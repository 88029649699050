import { authentication } from "@/utils/urlConstants";

export default ({ post, get }) => ({
  login(data) {
    return post(authentication.login, data);
  },
  getProfileInfo() {
    return get(authentication.profileInfo);
  },
  getProfileUpdate(data) {
    return puts(authentication.profileInfo, data);
  },
  changePassword(data) {
    return put(authentication.changePassword, data);
  },
  logout() {
    return post(authentication.logout);
  },
});
