import { notification } from "@/utils/urlConstants";

export default ({ get, patch }) => ({
  getNotificationList(params) {
    return get(`${notification.base}`, { params: params });
  },
  notificationMarkAsRead(payload) {
    return patch(`${notification.markAsSeen}${payload.id}/`, payload.data);
  },
  notificationMarkAllAsRead(data) {
    return get(`${notification.markAllAsSeen}`, data);
  },
  getNotificationCount() {
    return get(`${notification.notificationCount}`);
  },
});
