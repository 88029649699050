import axiosInstance from "./axios";
import authentication from "@/services/user/authentication";
import country from "@/services/configuration/country";
import branch from "@/services/configuration/branch";
import project from "@/services/configuration/project";
import driver from "@/services/asset/driver";
import vehicle from "@/services/asset/vehicle";
import vehicleType from "@/services/configuration/vehicleType";
import documentType from "@/services/configuration/documentType";
import warehouse from "@/services/configuration/warehouse";
import user from "@/services/user/user";
import item from "@/services/item";
import order from "@/services/order";
import integration from "@/services/integration";
import tasks from "@/services/tasks";
import exception from "@/services/configuration/exception";
import checklist from "@/services/configuration/checklist";
import trips from "@/services/trips";
import dashboard from "@/services/dashboard";
import report from "@/services/report";
import chat from "@/services/chat/chat";
import mobileapk from "@/services/mobileapk";
import driverDocument from "@/services/asset/driverDocument";
import notification from "@/services/notification/notification";
import support from "@/services/support";

export default {
  authentication: authentication(axiosInstance),
  country: country(axiosInstance),
  branch: branch(axiosInstance),
  project: project(axiosInstance),
  exception: exception(axiosInstance),
  driver: driver(axiosInstance),
  vehicle: vehicle(axiosInstance),
  vehicleType: vehicleType(axiosInstance),
  documentType: documentType(axiosInstance),
  warehouse: warehouse(axiosInstance),
  user: user(axiosInstance),
  item: item(axiosInstance),
  order: order(axiosInstance),
  integration: integration(axiosInstance),
  tasks: tasks(axiosInstance),
  checklist: checklist(axiosInstance),
  trips: trips(axiosInstance),
  dashboard: dashboard(axiosInstance),
  report: report(axiosInstance),
  chat: chat(axiosInstance),
  report: report(axiosInstance),
  driverDocument: driverDocument(axiosInstance),
  report: report(axiosInstance),
  mobileapk: mobileapk(axiosInstance),
  notification: notification(axiosInstance),
  support: support(axiosInstance),
};
