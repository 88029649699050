var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"400px","nudge-left":"350","nudge-top":"-20","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"top":"","overlap":"","content":_vm.notificationCount,"color":_vm.notificationCount == 0 ? '' : 'primary'}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mr-n5",class:_vm.openNotifications ? 'primary--text' : '',attrs:{"text":""},on:{"click":function($event){return _vm.getNotificationList(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.openNotifications),callback:function ($$v) {_vm.openNotifications=$$v},expression:"openNotifications"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"width":"400px","flat":""}},[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"text-body-1 white--text"},[_vm._v(" Notifications ")]),_c('v-spacer'),(_vm.userNotifications && _vm.userNotifications.length != 0)?_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.markAllNotificationAsRead}},[_vm._v(" mdi-delete ")]):_vm._e()],1),_c('v-card-text',{staticClass:"pa-0 overflow-y-auto",staticStyle:{"max-height":"350px"},attrs:{"id":"notificationCard"},on:{"scroll":_vm.handleNotificationScroll}},[(!_vm.userNotifications.length)?_c('div',{staticClass:"d-flex justify-center pa-3"},[_c('span',[_vm._v("No Notifications To Show!")])]):_c('div',_vm._l((_vm.userNotifications),function(notification,index){return _c('div',{key:index},[_c('v-row',{class:[
              'ma-0',
              'my-1',
              notification.is_read ? 'grey--text' : '',
            ],style:(!notification.is_read
                ? { backgroundColor: '#e5f5fc', cursor: 'Pointer' }
                : { backgroundColor: 'white', cursor: 'Pointer' })},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('hr',{staticStyle:{"opacity":"0.4"}})]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","padding":"15px"}},[(
                    notification.notification.notification_category == 'Job'
                  )?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-train-car-container ")]):(
                    notification.notification.notification_category == 'Bid'
                  )?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-cash-marker ")]):(
                    notification.notification.notification_category ==
                    'Campaign'
                  )?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-ticket-percent ")]):(
                    notification.notification.notification_category ==
                    'Invoice'
                  )?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-receipt-text ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-receipt-text ")])],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"10"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-body-2 font-weight-bold",attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(notification.notification.title)+" ")])]),_c('v-col',{staticClass:"text-caption",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(notification.notification.message)+" ")]),_c('v-col',{staticClass:"text-caption font-weight-bold mt-1 d-flex align-center",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.formatDate(notification.notification.created, true))+" ")]),(!notification.is_read)?_c('v-col',{staticClass:"text-caption font-weight-bold mt-1 d-flex justify-end pa-0 ma-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.userNotificationMarkAsRead(notification.id, index)}}},[_c('v-icon',{attrs:{"left":"","dark":"","color":"white"}},[_vm._v(" mdi-check ")]),_vm._v(" Mark as Read ")],1)],1):_vm._e()],1)],1)],1)],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }