<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    elevation="0"
    :small="isSetSmall"
    depressed
    class="pa-0"
  >
    <Tooltip top v-if="tooltipText" :tooltipText="tooltipText">
      <template #activator>
        <v-icon :size="iconSize" :color="iconColor"> {{ icon }} </v-icon>
      </template>
    </Tooltip>
    <v-icon v-else :size="iconSize" :color="iconColor"> {{ icon }} </v-icon>
  </v-btn>
</template>

<script>
import Tooltip from "@/components/General/Tooltip.vue";
export default {
  components: { Tooltip },
  props: {
    icon: {
      required: true,
    },
    color: {
      default: "primary",
    },
    tooltipText: {
      type: String,
      default: null,
    },
    iconColor: {
      default: "white",
    },
    iconSize: {
      default: "20px",
    },
    isSetSmall: {
      default: true,
    },
  },
};
</script>
