import { order, version } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getOrderList(params = {}) {
    return get(`${order.base}`, {
      params: params,
    });
  },
  getOrderSelectList(params = {}) {
    return get(`${order.base}select/`, {
      params: params,
    });
  },
  getOrderObject(id) {
    return get(`${order.base}${id}/`);
  },
  addOrder(data) {
    return post(`${order.base}`, data);
  },
  updateOrder(data, id) {
    return put(`${order.base}${id}/`, data);
  },
  editCustomerDetails(data, id) {
    return put(`${order.base}${id}/update_customer_details/`, data);
  },
  getOrderItemsList(params = {}) {
    return get(`${version}line_items/`, { params: params });
  },
  getTaskItemsList(params = {}) {
    return get(`${version}task_items/`, { params: params });
  },
  updatePodRequired(id, data) {
    return put(`${order.base}${id}/pod_required_update/`, data);
  },
  getCustomerFeedback(params) {
    return get(`${version}customer_feedbacks/`, { params: params });
  },
  updateSalesPerson(data, id) {
    return put(`${order.base}${id}/salesperson_assigne/`, data );
  },
});
