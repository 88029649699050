<template>
  <a rel="noopener noreferrer">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      elevation="0"
      text
      depressed
      class="btn-block white--text"
      @click="getMobileAPPlink"
    >
      <v-icon class="mr-6">mdi mdi-cellphone-arrow-down-variant</v-icon>
      <span class="download-text">{{ text }}</span>
    </v-btn>
  </a>
</template>

<script>

export default {
  props: {
    text: {
      default: "Download APK",
    },
  },
  methods: {
   
  },
};
</script>

<style scoped>
.download-text {
  margin-left: 5px !important;
  text-transform: none;
}
.text-like {
  background: none;
  border: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}
</style>
