import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const driverRoutes = [
  {
    path: `${globalModuleConstants.driverModule.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.driverModule.value}-list`,
        component: () => import("@/pages/asset/team/TeamList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.driverModule.value}-add`,
        component: () => import("@/pages/asset/team/TeamAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${globalModuleConstants.driverModule.value}-edit`,
        component: () => import("@/pages/asset/team/TeamEdit.vue"),
      },
      {
        path: "details/:id",
        name: `${globalModuleConstants.driverModule.value}-details`,
        component: () => import("@/pages/asset/team/TeamDetails.vue"),
      },
    ],
  },
];

export default driverRoutes;
