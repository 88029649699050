import { chat } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getChatData(id) {
    return get(`${chat.base}${id}/`);
  },

  sendMessage(payload) {
    return post(`${chat.base}`, payload.data);
  },

  connection() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("user");
      let connection = new WebSocket(
        `${process.env.VUE_APP_SOCKET_URL}/ws/operations/?TOKEN=${token}`
      );
      connection.onopen = function () {
        resolve(connection);
      };
      connection.onerror = function (err) {
        reject(err);
      };
    });
  },
});
