import { dashboard } from "@/utils/urlConstants";
import { customer_feedbacks } from "@/utils/urlConstants";
import { order } from "@/utils/urlConstants";

export default ({ get, post }) => ({
  getdashboardTodaysTask(params = {}) {
    return get(`${dashboard.base}total_tasks_status/`, {
      params: params,
    });
  },
  getdashboardPendingTasks(params = {}) {
    return get(`${dashboard.base}driver_summary/`, {
      params: params,
    });
  },
  getTripsDetails(params = {}) {
    return get(`${dashboard.base}trip_details/`, {
      params: params,
    });
  },
  getDeliveryAccuracy(params = {}) {
    return get(`${dashboard.base}delivery_accuracy/`, {
      params: params,
    });
  },
  getDeliveryDetails(params = {}) {
    return get(`${dashboard.base}on_time_delivery_status/`, {
      params: params,
    });
  },
  getInstallationDetails(params = {}) {
    return get(`${dashboard.base}on_time_installation_status/`, {
      params: params,
    });
  },
  getDeliveryKPI(params = {}) {
    return get(`${customer_feedbacks}on_time_installation_status/`, {
      params: params,
    });
  },
  getOrderStatus(params = {}) {
    return get(`${order.base}get_order_status/`, {
      params: params,
    });
  },
  getOrderStatusDetails(params = {}) {
    return get(`${order.base}get_order_status_details/`, {
      params: params,
    });
  },
  getDeliveryKPI(params={}){
    return get(`${customer_feedbacks.base}delivery_kpi_status/`,{
      params:params,
    });
  }
});