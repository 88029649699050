<template>
  <div>
    <!-- Top Navigation -->
    <AppBar @toggleDrawer="drawer = !drawer" />

    <!-- Left Side Navigation -->
    <SideNavigation v-model="drawer" />

    <!-- Base Content -->
    <v-main class="background">
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { checkifLoggedIn, getUserDetailsLocalStorage } from "@/utils/functions";
import AppBar from "../components/BaseComponents/AppBar.vue";
import SideNavigation from "../components/BaseComponents/SideNavigation.vue";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "DefaultLayout",
  components: {
    AppBar,
    SideNavigation,
  },
  data() {
    return {
      // Drawer vars
      drawer: true,
    };
  },
  methods: {
    async checkIfLoggedIn() {
      let isLoggedIn = checkifLoggedIn();
      if (!isLoggedIn) {
        if (this.$route.path !== "/login") {
          this.$router.push({ path: "/login" });
        }
      } else {
        let user = getUserDetailsLocalStorage();
        if (
          ["normal_user", "admin", "system_administrator", "customer", "sales_representative"].indexOf(
            user.user_type
          ) > -1
        ) {
          if (["", "/"].indexOf(this.$route.path) > -1) {
            this.$router.push({ path: "/dashboard" });
          }
          // if (this.$route.fullPath.indexOf("/")) {

          // }
        }
        //  else if (["customer"].indexOf(user.user_type) > -1) {
        //   if (["", "/"].indexOf(this.$route.path) > -1) {
        //     this.$router.push({ push: "/" });
        //   }
        // console.log("fullPath", this.$route.fullPath.indexOf("/order"));
        // if (this.$route.fullPath.indexOf("/order")) {
        //   this.$router.push({ push: "/order/" });
        // }
        // }
        else {
          if (this.$route.fullPath.indexOf("/login")) {
            this.$router.push({ path: "/login" });
          }
        }
      }
    },
  },
  beforeMount() {
    this.checkIfLoggedIn();
  },
  async mounted() {
    if (window.google == undefined) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GMAP_KEY,
        version: "weekly",
        libraries: ["places", "geometry", "drawing"],
      });
      await loader.load();
    }
  },
};
</script>