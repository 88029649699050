import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const reportRoutes = [
  {
    path: `${globalModuleConstants.reportModule.pathName}/`,
    component: Blank,
    children: [
      {
        path: "/task-report",
        name: `${globalModuleConstants.reportModule.taskReportValue}`,
        component: () => import("@/pages/report/TaskReport.vue"),
        default: true,
      },
      {
        path: "/customer-feedback-report",
        name: `${globalModuleConstants.reportModule.customerFeedbackValue}`,
        component: () => import("@/pages/report/CustomerFeedbackReport.vue"),
        default: true,
      },
      {
        path: "/team-wise-operation-report",
        name: `${globalModuleConstants.reportModule.teamWiseReport}`,
        component: () => import("@/pages/report/TeamWiseOperationReport.vue"),
        default: true,
      },
      {
        path: "/dispatch-report",
        name: `${globalModuleConstants.reportModule.dispatchReportValue}`,
        component: () => import("@/pages/report/DispatchReport.vue"),
        default: true,
      },
    ],
  },
];

export default reportRoutes;
