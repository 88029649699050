import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const vehicleTypeRoutes = [
  {
    path: `${globalModuleConstants.vehicleTypeModule.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.vehicleTypeModule.value}-list`,
        component: () =>
          import("@/pages/config/vehicle-type/VehicleTypeList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.vehicleTypeModule.value}-add`,
        component: () =>
          import("@/pages/config/vehicle-type/VehicleTypeAdd.vue"),
      },
      {
        path: ":id/edit",
        name: `${globalModuleConstants.vehicleTypeModule.value}-edit`,
        component: () =>
          import("@/pages/config/vehicle-type/VehicleTypeEdit.vue"),
      },
    ],
  },
];

export default vehicleTypeRoutes;
