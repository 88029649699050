import { report } from "@/utils/urlConstants";

export default ({ get, post }) => ({
  getTaskReport(params = {}) {
    return get(`${report.taskReport}`, {
      params: params,
    });
  },
  getCustomerFeedbackReport(params = {}) {
    return get(`${report.customerFeedbackReport}`, {
      params: params,
    });
  },
  getTeamWiseReport(params = {}) {
    return get(`${report.teamWiseReport}`, {
      params: params,
    });
  },
  getDispatchReport(params = {}) {
    return get(`${report.dispatchReport}`, {
      params: params,
    });
  },
  downloadTaskReport(params = {}) {
    return get(`${report.taskReport}download_task_report_sheet`, {
      responseType: "blob",
      params: params,
    });
  },
  downloadCustomerFeedbacks(params = {}) {
    return get(
      `${report.customerFeedbackReport}download_customer_feedback_sheet`,
      {
        responseType: "blob",
        params: params,
      }
    );
  },
  downloadOperationReport(params = {}) {
    return get(`${report.teamWiseReport}download_operation_report_sheet`, {
      responseType: "blob",
      params: params,
    });
  },
  downloadDispatchReport(params = {}) {
    return get(`${report.dispatchReport}download_dispatch_report_sheet`, {
      responseType: "blob",
      params: params,
    });
  },
});
