import { checklist } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getChecklistList(params = {}) {
    return get(`${checklist.base}`, {
      params: params,
    });
  },
  getChecklistSelectList(params = {}) {
    return get(`${checklist.base}select/`, {
      params: params,
    })
  },
  getChecklistObject(id) {
    return get(`${checklist.base}${id}/`);
  },
  addChecklist(data) {
    return post(`${checklist.base}`, data);
  },
  updateChecklist(data, id) {
    return put(`${checklist.base}${id}/`, data);
  },
});
