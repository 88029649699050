import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const videoPlayerRoutes = [
  {
    path: `${globalModuleConstants.video_player.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.video_player.value}-list`,
        component: () => import("@/pages/videoPlayer/index.vue"),
        default: true,
      },
    ],
  },
];

export default videoPlayerRoutes;
