import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

import branchRoutes from "./branch";
import projectRoutes from "./project";
import warehouseRoutes from "./warehouse";
import vehicleTypeRoutes from "./vehicleType";
import documentTypeRoutes from "./documentType";
import exceptionRoutes from "./exception";
import checklistRoutes from "./checklist";

const configRoutes = [
  {
    path: `${globalModuleConstants.configurationModule.pathName}/`,
    component: Blank,
    children: [
      ...branchRoutes,
      ...projectRoutes,
      ...warehouseRoutes,
      ...vehicleTypeRoutes,
      ...documentTypeRoutes,
      ...exceptionRoutes,
      ...checklistRoutes,

    ],
    name: `${globalModuleConstants.configurationModule.value}`,
  },
]

export default configRoutes;
