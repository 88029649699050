import { user } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getUserList(params = {}) {
    return get(`${user.base}`, {
      params: params,
    });
  },
  getUserSelectList(params = {}) {
    return get(`${user.base}select/`, {
      params: params,
    });
  },
  getUserObject(params ={}) {
    return get(`${user.base}${params.id}/`,{
      params:params,
    });
  },
  addUser(data) {
    return post(`${user.base}`, data);
  },
  updateUser(data, id) {
    return put(`${user.base}${id}/`, data);
  },
});
