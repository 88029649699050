<template>
  <span :class="$vuetify.breakpoint.lg ? 'text-h6' : 'text-h5'">
    {{ title }}
  </span>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
  },
};
</script>

<style>
</style>