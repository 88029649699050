<template>
  <v-text-field
    autocomplete="new-password"
    hide-details="auto"
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    :clearable="clearable"
    :class="designClass"
    :value="value"
    :dense="dense"
    :rules="[
      ...(isRequired
        ? [(val) => !!val || `${label ? label : 'Field'} is Required`]
        : []),
      // ...(applyRegExp
      //   ? [
      //       (val) =>
      //         !val ||
      //         (val && $globals.specialCharactersRegExp.test(val)) ||
      //         'Field must contain at least one alphanumeric character. Allowed Special Characters are ,.-_',
      //     ]
      //   : []),

      ...fieldRules,
    ]"
    :label="label ? `${label}${isRequired ? ' *' : ''}` : ''"
    background-color="background"
    class="Border-Remove"
  />
</template>

<script>
export default {
  name: "InputTag",
  props: {
    dense: {
      default: true,
    },
    label: {
      required: false,
    },
    isRequired: {
      default: false,
      type: Boolean,
    },
    applyRegExp: {
      default: true,
    },
    value: {
      type: [String, Array, Date, Number],
      default: "",
    },
    designClass: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    fieldRules: {
      default: () => [],
    },
  },
};
</script>
