import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

import vehicleRoutes from "./vehicle";
import driverRoutes from "./driver";

const assetRoutes = [
  {
    path: `${globalModuleConstants.fleetManagementModule.pathName}/`,
    component: Blank,
    children: [
      ...vehicleRoutes,
      ...driverRoutes
    ],
    name: `${globalModuleConstants.fleetManagementModule.value}`,
  },
]

export default assetRoutes;
