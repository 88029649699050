import { Loader } from "@googlemaps/js-api-loader";

// Used in Google map
export function generateListOfHtmlTag(key, value) {
  return `<li class="pb-1 ma-0">
                <span class="text-primary text-caption font-weight-bold text-capitalize">${key.replace(
                  /_/g,
                  " "
                )} :</span>
                <span class="text-primary text-caption"> ${value}</span>
            </li>`;
}

export function loadGoogleInstance() {
  if (!window.google || !window.google.maps || window.google == undefined) {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GMAP_KEY,
      version: "weekly",
      libraries: ["places", "geometry", "drawing"],
    });
    loader.load();
  }
}
