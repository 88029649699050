import { trips } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getTripList(params = {}) {
    return get(`${trips.base}`, {
      params: params,
    });
  },
  getTripSelectList(params = {}) {
    return get(`${trips.base}select/`, {
      params: params,
    });
  },
  getTripObject(id) {
    return get(`${trips.base}${id}/`);
  },
  addTrip(data) {
    return post(`${trips.base}`, data);
  },
  updateTrip(data, id) {
    return put(`${trips.base}${id}/`, data);
  },
  getTripDetails(id) {
    return get(`${trips.base}${id}/get_trip_details/`);
  },
  getTripLogs(id) {
    return get(`${trips.base}${id}/get_trip_logs/`);
  },
  verifyTrip(payload) {
    return put(`${trips.base}${payload.id}/verify_trip/`, payload.data);
  },
  driverAssign(data,id){
    return put(`${trips.base}${id}/driver_assign/`, data);
  }
});
