<template>
  <div class="gauge-chart">
    <div class="gauge-title">{{ title }}</div>
    <apexchart
      type="radialBar"
      :options="chartOptions"
      :series="chartSeries"
      height="150"
    />
  </div>
</template>  
  
  <script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    gaugeFillColor() {
      if (this.value > 4 && this.value <= 5) {
        console.log("inside 1 if");
        return "#00FF00";
      } else if (this.value > 3 && this.value <= 4) {
        console.log("inside 2 if");
        return "#66CC33";
      } else if (this.value > 2 && this.value <= 3) {
        console.log("inside 3 if");
        return "#FFFF00";
      } else if (this.value > 1 && this.value <= 2) {
        console.log("inside 4 if");
        return "#FFCC00";
      } else {
        console.log("inside 5 if");
        return "#FF0000";
      }
    },
    chartOptions() {
      return {
        chart: {
          type: "radialBar",
          offsetX: -20,
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: "70%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 8,
                fontSize: "16px",
                color: "#333",
              },
            },
          },
        },
        fill: {
          colors: [this.gaugeFillColor],
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        series: [this.value],
      };
    },
    chartSeries() {
      return [this.value * 20];
    },
  },
};
</script>
  
  <style scoped>
.gauge-chart {
  text-align: center;
  position: relative;
}

.gauge-title {
  font-size: 16px;
  margin-right: 35px;
}
</style>
  