const logo = require("@/assets/images/logo.png");

// driverProfileImage
// const profileImage = require("@/assets/user.png");

// const defaultUserImage = require("@/assets/default-user.jpg");

const uaeCoordinates = {
  latitude: 24.4539,
  longitude: 54.3773
}
// map icons
// const mapIcons = {
//   warehouse: require("@/assets/mapIcons/Warehouse.png"),
//   truck: require("@/assets/mapIcons/Truck.png"),
//   ordersIcons: {
//     orderUnassigned: require("@/assets/mapIcons/order_location/Order_Unassigned.png"),
//     orderAssigned: require("@/assets/mapIcons/order_location/Order_Assigned.png"),
//     orderPickupIcon: require("@/assets/mapIcons/order_location/Order_Pickup.png"),
//     orderEnrouteIcon: require("@/assets/mapIcons/order_location/Order_Enroute.png"),
//     orderSuccessfulIcon: require("@/assets/mapIcons/order_location/Order_Successful.png"),
//     orderFailedIcon: require("@/assets/mapIcons/order_location/Order_Failed.png"),
//     orderCancelledIcon: require("@/assets/mapIcons/order_location/Order_cancelled.png"),
//   },
//   tripPlanning: require("@/assets/mapIcons/Location.png")
// };

const colors = {
  solid: {
    primary: "#9B182F",
    unassigned: "#607D8B",
    assigned: "#2196F3",
    pickedup: "#FF8F00",
    partially_delivered: "#9C27B0",
    failed: "#B71C1C",
    cancelled: "#880E4F",
    delivered: "#2E7D32",
    successful: "#2E7D32",
    // icons color
    orange: "#f5841f",
    red: "#ff3f3b",
    blue: "#4F508F"
  },
  light: {
    light_primary: "#FFECEF",
    light_unassigned: "#90A4AE",
    light_assigned: "#90CAF9",
    light_pickedup: "#FFB300",
    light_partially_delivered: "#CE93D8",
    light_failed: "#E53935",
    light_cancelled: "#F48FB1",
    light_delivered: "#66BB6A",
    light_successful: "#66BB6A",
    light_grey: "#E6E7E8",

    // icons color
    light_orange: "#fab079",
    light_red: "#f97d9d",
    light_blue: "#41dde5"
  },
};

// Icons Constants Main Layout

const sideNavigationIcons = {
  dashboard: "mdi-monitor-dashboard",
  operations: "mdi-cog-transfer-outline",
  trips: "mdi-truck-fast-outline",
  trip_planning: "mdi-map-marker-distance",
  trip_planning_new: "mdi-map-marker-distance",
  trip_planning_templates: "mdi-file-cad",
  fleet_management: "mdi-bus-multiple",
  drivers: "mdi-card-account-details-outline",
  orders: "mdi-archive-outline",
  order_history: "mdi-archive-search",
  integration_log: "mdi-archive-edit-outline",
  configuration: "mdi-cog",
  zones: "mdi-shape-square-plus",
  statuses: "mdi-list-status",
  item_master: "mdi-inbox-multiple",
  customers: "mdi-account-group",
  users: "mdi-account-check",
  vehicles: "mdi-truck",
  roles: "mdi-account-key",
  support: "mdi-face-agent",
  reports: "mdi-chart-areaspline",
  report_modules: {
    order_report: "mdi-archive-clock",
    trip_order: "mdi-map-marker-distance",
    driver_report: "mdi-account-tie",
    vehicle_report: "mdi-bus-clock",
    driver_expense_report: "mdi-chart-histogram",
    fleet_utilization_report: "mdi-truck-cargo-container",
    capacity_utilization_report: "mdi-rv-truck",
  },
};

// Icons Constants Modules

const configurationModuleIcons = {
  branch: "mdi-map",
  planning_template: "mdi-file-cad",
  project: "mdi-map",
  zones: "mdi-shape-square-plus",
  reason: "mdi-list-status",
};

const fleetManagementModuleIcons = {
  drivers: "mdi-card-account-details-outline",
  vehicles: "mdi-truck",
};

// GlobalIcons Constants ==> ex , filter,search

const globalIcons = {
  filter: "mdi-filter",
  search: "",
  edit: "mdi-pencil",
  view: "mdi-eye",
  delete: "mdi-delete",
  logs: "mdi-alpha-l-box",
  checkCircleIcon: "mdi-check-circle",
  closeCircleIcon: "mdi-close-circle",
  changeProject: "mdi-alpha-p-box",
  documentsUpload: "mdi-file-upload-outline"
};

const item_master = {
  dryStorage: "mdi-white-balance-sunny",
  chillStorage: "mdi-ice-pop",
  frozenStorage: "mdi-snowflake"
};

export default {
  globalIcons,
  configurationModuleIcons,
  sideNavigationIcons,
  fleetManagementModuleIcons,
  logo,
  colors,
  item_master,
  // profileImage,
  // mapIcons,
  // defaultUserImage,
  uaeCoordinates,
};
