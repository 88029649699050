import { exception } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getExceptionList(params = {}) {
    return get(`${exception.base}`, {
      params: params,
    });
  },
  getExceptionSelectList(params = {}) {
    return get(`${exception.base}select/`, {
      params: params,
    })
  },
  getExceptionObject(id) {
    return get(`${exception.base}${id}/`);
  },
  addException(data) {
    return post(`${exception.base}`, data);
  },
  updateException(data, id) {
    return put(`${exception.base}${id}/`, data);
  },
});
