var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',_vm._g(_vm._b({staticClass:"Border-Remove",class:_vm.designClass,attrs:{"autocomplete":"new-password","hide-details":"auto","outlined":"","clearable":_vm.clearable,"value":_vm.value,"dense":_vm.dense,"rules":[
    ...(_vm.isRequired
      ? [(val) => !!val || `${_vm.label ? _vm.label : 'Field'} is Required`]
      : []),
    // ...(applyRegExp
    //   ? [
    //       (val) =>
    //         !val ||
    //         (val && $globals.specialCharactersRegExp.test(val)) ||
    //         'Field must contain at least one alphanumeric character. Allowed Special Characters are ,.-_',
    //     ]
    //   : []),

    ..._vm.fieldRules,
  ],"label":_vm.label ? `${_vm.label}${_vm.isRequired ? ' *' : ''}` : '',"background-color":"background"}},'v-text-field',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }