<template>
  <v-app>
    <router-view />
    <BaseSnackbar
      v-model="showToastMessage"
      :color="snackBarColor"
      :message="snackbarMessage"
      :elevation="0"
    />
    <BaseLoader :isLoader="showLoader" />
  </v-app>
</template>

<script>
import BaseSnackbar from "@/components/BaseComponents/Snackbar.vue";
import BaseLoader from "@/components/BaseComponents/Loader.vue";

export default {
  name: "mainApp",
  components: {
    BaseLoader,
    BaseSnackbar,
  },
  data: () => ({
    showToastMessage: false,
    snackBarColor: "primary",
    snackbarMessage: "",
    showLoader: false,
  }),
  mounted() {
    this.$bus.$on("showToastMessage", ({ color, message }) => {
      this.snackBarColor = color;
      this.snackbarMessage = message;
      this.showToastMessage = true;
    });
    this.$bus.$on("showLoader", (value) => {
      this.showLoader = value;
    });
  },
  beforeDestroy() {
    this.$bus.$off("showToastMessage");
    this.$bus.$off("showLoader");
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base.scss";
</style>
