export const version = "/api/v1/";

export const authentication = {
  login: `${version}login/`,
  profileInfo: `${version}/user/profile/`,
  logout: `${version}/logout`,
  // changePassword: `${user.base}change_password/`,
};

export const country = {
  base: `${version}countries/`,
};

export const branch = {
  base: `${version}branch/`,
};

export const project = {
  base: `${version}project/`,
};

export const driver = {
  base: `${version}driver/`,
};

export const vehicle = {
  base: `${version}vehicle/`,
};

export const vehicleType = {
  base: `${version}vehicle_type/`,
};

export const documentType = {
  base: `${version}document_master/`,
};

export const warehouse = {
  base: `${version}warehouse/`,
};

export const user = {
  base: `${version}users/`,
};

export const item = {
  base: `${version}items/`,
};

export const order = {
  base: `${version}orders/`,
};

export const integration = {
  base: `${version}integration/`,
};

export const tasks = {
  base: `${version}tasks/`,
  operationBase: `${version}operation_tasks/`,
};

export const exception = {
  base: `${version}task_exceptions/`,
};

export const checklist = {
  base: `${version}checklists/`,
};

export const trips = {
  base: `${version}trips/`,
};

export const customer_feedbacks = {
  base: `${version}customer_feedbacks/`,
};

export const dashboard = {
  base: `${version}dashboard/`,
};

export const report = {
  taskReport: `${version}task_report/`,
  customerFeedbackReport: `${version}customer_feedback_report/`,
  teamWiseReport: `${version}operation_report/`,
  dispatchReport: `${version}dispatch_report/`,
};

export const chat = {
  base: `${version}driver/chat/`,
};
export const driverDocument = {
  base: `${version}driver_documents/`,
};

export const mobileapk = {
  base: `${version}mobile_app/`,
};

// notification endpoints
export const notification = {
  base: `${version}user_notifications/`,
  markAsSeen: `${version}user_notifications/`,
  markAllAsSeen: `${version}user_notifications/mark_all_as_read/`,
  notificationCount: `${version}user_notifications/unread_notification_count/`,
};

export const support = {
  base: `${version}support/`,
};
