import { documentType } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getDocumentTypeList(params = {}) {
    return get(`${documentType.base}`, {
      params: params,
    });
  },
  getDocumentTypeSelectList(params = {}) {
    return get(`${documentType.base}select/`, {
      params: params,
    });
  },
  getDocumentTypeObject(id) {
    return get(`${documentType.base}${id}/`);
  },
  addDocumentType(data) {
    return post(`${documentType.base}`, data);
  },
  updateDocumentType(data, id) {
    return put(`${documentType.base}${id}/`, data);
  },
});
