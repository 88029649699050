import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const itemRoutes = [
  {
    path: `${globalModuleConstants.item_master.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.item_master.value}-list`,
        component: () => import("@/pages/item/ItemList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.item_master.value}-add`,
        component: () => import("@/pages/item/ItemAdd.vue"),
      },
      {
        path: ":id/edit",
        name: `${globalModuleConstants.item_master.value}-edit`,
        component: () => import("@/pages/item/ItemEdit.vue"),
      },
    ],
  },
];

export default itemRoutes;
