import { branch } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getBranchList(params = {}) {
    return get(`${branch.base}`, {
      params: params,
    });
  },
  getBranchSelectList(params = {}) {
    return get(`${branch.base}select/`, {
      params: params,
    })
  },
  getBranchObject(id) {
    return get(`${branch.base}${id}/`);
  },
  addBranch(data) {
    return post(`${branch.base}`, data);
  },
  updateBranch(data, id) {
    return put(`${branch.base}${id}/`, data);
  },
});
