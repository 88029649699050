import { driver, version } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getDriverList(params = {}) {
    return get(`${driver.base}`, {
      params: params,
    });
  },
  getDriverSelectList(params = {}) {
    return get(`${driver.base}select/`, {
      params: params,
    });
  },
  getDriverObject(id) {
    return get(`${driver.base}${id}/`);
  },
  addDriver(data) {
    return post(`${driver.base}`, data);
  },
  updateDriver(data, id) {
    return put(`${driver.base}${id}/`, data);
  },
  getDriverDetails(id) {
    return get(`${driver.base}${id}/view/`);
  },
  getDriverCompletedTasks(id, params) {
    return get(`${driver.base}${id}/completed_task/`, {
      params: params,
    });
  },
  getDriverDelayedTrips(id, params) {
    return get(`${driver.base}${id}/delayed_trips/`, {
      params: params,
    });
  },
  getDriverDelayedTasks(id, params) {
    return get(`${driver.base}${id}/delayed_delivery_task/`, {
      params: params,
    });
  },
  getDelayedInstallationTasks(id, params) {
    return get(`${driver.base}${id}/delayed_installation_task/`, {
      params: params,
    });
  },
  getCustomerFeedback(params) {
    return get(`${version}customer_feedbacks/customer_feedback_stats/`, {
      params: params,
    });
  },
  getOverTimeRatio(id) {
    return get(`${driver.base}${id}/driver_overtime_ratio/`,);
  },
  getDriverDelayRatio(id) {
    return get(`${driver.base}${id}/get_delay_percentage/`,);
  },
});
