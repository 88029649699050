import { integration } from "@/utils/urlConstants";

export default ({ get, post}) => ({
    getIntegrationList(params = {}) {
      return get(`${integration.base}`, {
        params: params,
      });
    },
    fetchIntegrationFiles(params = {}) {
        return get(`${integration.base}fetch_integration_files/`, {
          params: params,
        });
      },
    uploadDummyFile(params = {}) {
      return post(`${integration.base}upload_dummy/`, {
        params: params,
      });
    },
    downloadIntegrationFile(id) {
      const newWindow = window.open(`${integration.base}${id}/download_file/`, '_blank');
      return get(`${integration.base}${id}/download_file/`);
    },
    manualFileUpload(data) {
      return post(`${integration.base}manual_file_upload/`,data);
    },
    

  });