<template>
  <v-app-bar
    v-bind="$attrs"
    :clipped-left="true"
    :clipped-right="true"
    fixed
    app
    elevation="2"
    color="white"
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')" />

    <!-- Brand Logo -->
    <img class="Brand-Logo" alt="Brand Logo" :src="$globalConstant.logo" />
    <v-spacer></v-spacer>

    <!-- Change Language -->
    <!-- <MultiSelect
      :items="language"
      v-model="currentLanguage"
      label="Language"
      item-text="label"
      item-value="value"
      class="notranslate max-width-150-px"
      @change="googleTranslateElementInit"
    /> -->
    <!-- Notification -->
    <BellNotification v-model="openNotifications" />

    <v-menu v-model="profileMenu" offset-y content-class="Profile-Menu">
      <!-- v-if="currentProfile.first_name"  -->
      <template v-slot:activator="{ on, attrs }">
        <table v-bind="attrs" v-on="on" class="ml-6">
          <tbody>
            <tr>
              <td rowspan="2" class="pr-2">
                <v-avatar color="primary" size="36">
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
              </td>
              <th class="text-left" v-if="currentProfile">
                <span v-if="currentProfile && currentProfile.first_name">
                  {{ currentProfile.first_name }}
                </span>
                <span v-if="currentProfile && currentProfile.last_name">
                  {{ currentProfile.last_name }}
                </span>
              </th>
            </tr>
            <tr>
              <Tooltip top tooltipText="Branch">
                <template #activator>
                  <v-chip
                    small
                    class="primary lighten-1 white--text"
                    v-if="currentProfile && currentProfile.branch_name"
                  >
                    {{ currentProfile.branch_name }}
                  </v-chip>
                </template>
              </Tooltip>
            </tr>
          </tbody>
        </table>
      </template>
      <v-card>
        <v-card-text>
          <!-- <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon small @click="openUserProfileDialog">
                      mdi-pencil
                    </v-icon>
                  </div>
                </template>
                <span class="text-capitalize">Edit User Details</span>
              </v-tooltip>
            </v-col>
          </v-row> -->
          <table class="Normal-Table">
            <tbody>
              <tr>
                <td>Name</td>
                <th class="px-2">:</th>
                <th>
                  {{ currentProfile.first_name }}
                  {{ currentProfile.last_name }}
                </th>
              </tr>
              <tr>
                <td>Username</td>
                <th class="px-2">:</th>
                <th>
                  {{ currentProfile.username }}
                </th>
              </tr>
              <tr>
                <td>Email</td>
                <th class="px-2">:</th>
                <th>
                  {{ currentProfile.email }}
                </th>
              </tr>
              <tr>
                <td>Contact No</td>
                <th class="px-2">:</th>
                <th>
                  {{ currentProfile.contact_number }}
                </th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-1">
          <v-list dense class="Full-Width">
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-button">
                  Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-actions>
      </v-card>
    </v-menu>
    <div id="dialogs">
      <UserProfile v-model="openProfileDialog" :userId="currentProfile.id" />
    </div>
  </v-app-bar>
</template>

<script>
import Tooltip from "@/components/General/Tooltip.vue";
import BellNotification from "@/components/General/BellNotification.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { getCookie, setCookie, eraseCookie } from "@/utils/cookieHandlers";
import UserProfile from "../Forms/UserProfile.vue";

export default {
  components: {
    MultiSelect,
    BellNotification,
    Tooltip,
    UserProfile,
  },
  props: {
    companyNotifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: "Fero",
      ltPermission: null,
      profileMenu: false,
      language: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "Arabic",
          value: "ar",
        },
      ],
      currentLanguage: "en",
      openProfileDialog: false,
      openNotifications: false,
    };
  },
  computed: {
    currentProfile() {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      return userDetails;
    },
  },
  methods: {
    openUserProfileDialog() {
      this.openProfileDialog = true;
    },
    updateSelectedBranch(id) {
      const payload = {
        branch: id,
      };
      this.$api.authentication.updateSelectedBranch(payload).then(async () => {
        this.profileMenu = false;
        await this.getProfileInfo();
        location.reload();
      });
    },

    onClose() {
      // this.$ws && this.$ws.removeInstance();
    },
    onMessage(ev) {
      // if (ev && ev.data) {
      //   const wsMessage =
      //     typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
      //   this.handleReceivedMessage(wsMessage);
      // }
    },
    handleReceivedMessage(wsMessage) {
      // const wsMessageType = wsMessage.type;
      // if (wsMessageType) {
      //   if (wsMessageType == "user_notification_received") {
      //     this.$bus.$emit("userNotificationReceived", wsMessage.data);
      //   }
      //   if (
      //     [
      //       "chat_history",
      //       "organization_message_received",
      //       "transporter_message_received",
      //       "driver_message_received",
      //     ].indexOf(wsMessageType) > -1
      //   ) {
      //     this.$bus.$emit("chatMessageReceived", wsMessage);
      //   }
      // }
    },
    onOpen() {
      // this.$bus.$emit("socketConnected");
    },

    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    async setupWS() {
      // if (!this.$ws.instance) {
      //   await this.$ws.createInstance(this.currentWSUserType);
      //   this.$ws.instance.onopen = this.onOpen;
      // } else {
      //   this.onOpen();
      // }
      // this.$ws.instance.onmessage = this.onMessage;
    },
    getProfileInfo() {
      // this.$bus.$emit("showLoader", true);
      //   return this.$api.authentication
      //     .getProfileInfo()
      //     .then((res) => {
      //       if (res && res.data) {
      //         localStorage.setItem("userProfile", JSON.stringify(res.data));
      //         this.currentProfile =
      //           JSON.parse(localStorage.getItem("userProfile")) || {};
      //         const code = res.data?.currency_code;
      //         if (code) this.$globals.currency = code;
      //         const phoneCode = res.data?.preferred_phone_code;
      //         if (phoneCode) this.$globals.phoneCode = phoneCode;
      //         this.setupWS();
      //       }
      //       this.$bus.$emit("showLoader", false);
      //     })
      //     .catch(() => {
      //       this.$bus.$emit("showLoader", false);
      //     });
    },
    checkCurrentLang() {
      this.currentLanguage = getCookie("currentLanguage")
        ? getCookie("currentLanguage")
        : "en";
    },
    googleTranslateElementInit(language = null, reload = false) {
      if (!language && this.currentLanguage === language) {
        return;
      }

      if (getCookie("googtrans")) {
        eraseCookie("googtrans");
      }

      const previousLangugage = getCookie("currentLanguage") ?? "en";

      setCookie(
        "googtrans",
        `/${previousLangugage}/${this.currentLanguage}`,
        import.meta.env.VITE_APP_COOKIEDOMAIN
      );

      setCookie("currentLanguage", language);

      new google.translate.TranslateElement(
        {
          pageLanguage: this.currentLanguage,
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en",
        },
        "app"
      );

      // this.currentLanguage = language;

      setCookie(
        "currentLanguage",
        this.currentLanguage,
        import.meta.env.VITE_APP_COOKIEDOMAIN
      );
      if (reload || previousLangugage != this.currentLanguage) {
        location.reload();
      }
    },

    loadLang() {
      // setCookie("googtrans", `/en`, 30);
      // setCookie("currentLanguage", this.currentLanguage, 30);
      new google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en",
        },
        "app"
      );
    },
  },
  mounted() {
    // this.$bus.$on("changeBranch", this.updateSelectedBranch);
    // this.$bus.$on("reloadProfileData", this.getProfileInfo);
    // this.getProfileInfo();

    const interval = setInterval(() => {
      if (window && window.google && window.google.translate) {
        clearInterval(interval);
        this.checkCurrentLang();
        this.loadLang();
      }
    }, 300);
  },
};
</script>

<style scoped>
.Profile-Menu {
  margin-top: 10px;
}

.Branch-Height-Class {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
