import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const operationRoutes = [
  {
    path: `${globalModuleConstants.operationModule.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.operationModule.value}-list`,
        component: () => import("@/pages/operations/index.vue"),
        default: true,
      },
    ],
  },
];

export default operationRoutes;
