<template>
  <div class="">
    <v-row class="ma-0" justify="space-between">
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        sm="6"
        class="align-self-center ma-0 pb-1"
      >
        <CustomTitle title="Dashboard" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        sm="6"
        class="align-self-end ma-0 pb-1"
      >
        <v-row class="ma-0">
          <v-col cols="5" class="pr-2 ma-0 d-flex align-center">
            <DateField
              name="dashboard-from-date"
              label="From Date"
              :inputProps="{
                dense: true,
                clearable: true,
                required: true,
                'hide-details': 'auto',
                'error-messages': formErrors.expiry_date,
              }"
              backgroundColor="white"
              :rules="[validateFromDate]"
              :isRequired="true"
              :max="today"
              v-model="teamFilter.from_date"
              @change="delete formErrors.expiry_date"
            />
          </v-col>
          <v-col cols="5" class="d-flex justify-end align-center ma-0 pa-0">
            <DateField
              name="dashboard-from-date"
              label="To Date"
              :inputProps="{
                dense: true,
                clearable: true,
                required: true,
                'hide-details': 'auto',
                'error-messages': formErrors.expiry_date,
              }"
              backgroundColor="white"
              :isRequired="true"
              :min="teamFilter.from_date"
              :max="today"
              v-model="teamFilter.to_date"
              @change="delete formErrors.expiry_date"
            />
          </v-col>
          <v-col cols="2" class="d-flex justify-end align-center">
            <SubmitButton small @click="applyTimeRangeFilter" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="align-center">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            <!-- Today's Task -->
            {{ `${$globalModuleConstants.dashboardModule.tasks}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            class="d-flex justify-center align-center"
            style="height: 88%"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="12" lg="12" sm="12" xl="12">
                <v-card class="total-task-card pa-5" outlined>
                  <v-card-text
                    class="d-flex flex-column justify-center align-center"
                  >
                    <span class="text-h6 black--text">
                      {{ todaysTaskChart.total_tasks }}
                    </span>

                    <span class="text-subtitle-1">
                      {{
                        `${$globalModuleConstants.dashboardModule.total_task}`
                      }}
                    </span></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col cols="12" md="4" lg="4" sm="4" xl="4">
                <v-card class="pending-card pa-5" outlined>
                  <v-card-text
                    class="d-flex flex-column justify-center align-center"
                  >
                    <span class="text-h6 black--text">
                      {{ todaysTaskChart.pending_tasks }}
                    </span>

                    <span class="text-subtitle-1">
                      {{ `${$globalModuleConstants.dashboardModule.pending}` }}
                    </span></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col cols="12" md="4" lg="4" sm="4" xl="4">
                <v-card class="in-progress-card pa-5" outlined>
                  <v-card-text
                    class="d-flex flex-column justify-center align-center"
                  >
                    <span class="text-h6 black--text">
                      {{ todaysTaskChart.in_progress_tasks }}
                    </span>

                    <span class="text-subtitle-1">
                      {{
                        `${$globalModuleConstants.dashboardModule.in_progress}`
                      }}
                    </span></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="4" xl="4">
                <v-card class="completed-card pa-5" outlined>
                  <v-card-text
                    class="d-flex flex-column justify-center align-center"
                  >
                    <span class="text-h6 black--text">
                      {{ todaysTaskChart.completed_tasks }}
                    </span>

                    <span class="text-subtitle-1">
                      {{
                        `${$globalModuleConstants.dashboardModule.completed}`
                      }}
                    </span></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            {{ `${$globalModuleConstants.dashboardModule.pendingTasks}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2">
            <StackedBarChart
              v-if="chart1"
              :labels="labels1"
              :datasets="chartDatasets1"
              :options="stackBarOptions"
              :height="364"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            {{ `${$globalModuleConstants.dashboardModule.tripChart}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2">
            <BarChart
              v-if="chart2"
              ref="barChart"
              :labels="barChartData.labels"
              :datasets="barChartData"
              :options="stackBarOptions"
              :height="350"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            {{ `${$globalModuleConstants.dashboardModule.orderChart}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="chart3">
              <v-col v-if="chart3" cols="12" md="4" lg="4" sm="4" xl="4">
                <DoughnutChart
                  ref="donoutChart"
                  :labels="donoutChartData.labels"
                  :data="donoutChartData"
                  :options="donutOptions"
                  :height="485"
                />
              </v-col>

              <v-col cols="12" md="8" lg="8" sm="8" xl="8">
                <v-card v-show="chart3" elevation="0" justify="center">
                  <v-row class="ma-0 pa-5">
                    <v-col
                      cols="12"
                      v-for="(item, index) in orderStatusDetailData"
                      :key="index"
                    >
                      <v-row>
                        <v-col
                          cols="5"
                          class="d-flex justify-start align-center"
                        >
                          <v-icon
                            small
                            class="mr-2"
                            :style="{ color: item.color }"
                          >
                            {{ item.icon }}
                          </v-icon>

                          <h5
                            class="text-caption font-weight-medium text-capitalize align-center"
                          >
                            {{ item.label.replaceAll("_", " ") }}
                          </h5>
                        </v-col>

                        <v-col cols="3" class="d-flex justify-end">
                          <v-row>
                            <v-col class="d-flex justify-space-around">
                              <h5 class="text-caption font-weight-bold">
                                {{ item.value ? item.value : 0 }}
                              </h5>

                              <h5 class="text-caption font-weight-bold">
                                {{
                                  item.percentage != 0 ? item.percentage : 0
                                }}
                                %
                              </h5>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          cols="4"
                          class="d-flex justify-center align-center"
                        >
                          <div
                            :style="{
                              width: `${item.percentage}%`,

                              backgroundColor: `${item.color[index]}`,

                              height: '25px',
                            }"
                          ></div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-divider></v-divider> -->
                    </v-col>
                    <v-col cols="12" class="ml-6">
                      <!-- <v-row>
                        <div class="info-label" style="margin-left: 3%">
                          {{
                            `${$globalModuleConstants.dashboardModule.total}`
                          }}
                        </div>
                        <div class="info-label" style="margin-left: 29%">
                          {{ total_order_count }}
                        </div>
                        <div class="info-label" style="margin-left: 6%">
                          {{ total_order_percentage }}%
                        </div>
                      </v-row> -->
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <div >
              <h5 >
                Data not available
              </h5>
            </div>
            </v-row>
          </v-card-text>
          <!-- <v-card-text v-else>
            <div class="d-flex justify-center py-5">
              <h5 class="text-subtitle-1 font-weight-bold">
                Data not available
              </h5>
            </div>
          </v-card-text> -->
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title
            class="d-flex align-center justify-space-between chart-titles"
          >
            <v-row>
              <v-col cols="6">
                <span>
                  {{ `${$globalModuleConstants.dashboardModule.deliveryKPI}` }}
                </span>
              </v-col>
              <v-col cols="6" class="pa-1 ma-0">
                <SelectField
                  :isSearchRequired="false"
                  name="dashboard-filter-project"
                  :itemsList="allDrivers"
                  v-model="teamFilter.driver"
                  label="Select Team"
                  item-text="driver_name"
                  item-value="id"
                  @change="resetGaugeValuesAndFetchData"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-12">
            <v-row>
              <v-col cols="4" class="d-flex align-center">
                <GaugeChart
                  v-if="chart7"
                  :value="gaugeValue1"
                  :title="gaugeTitle1"
                />
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="6">
                    <GaugeChart
                      v-if="chart7"
                      :value="gaugeValue2"
                      :title="gaugeTitle2"
                    />
                  </v-col>
                  <v-col cols="6">
                    <GaugeChart
                      v-if="chart7"
                      :value="gaugeValue3"
                      :title="gaugeTitle3"
                    />
                  </v-col>
                  <v-col cols="6">
                    <GaugeChart
                      v-if="chart7"
                      :value="gaugeValue4"
                      :title="gaugeTitle4"
                    />
                  </v-col>
                  <v-col cols="6">
                    <GaugeChart
                      v-if="chart7"
                      :value="gaugeValue5"
                      :title="gaugeTitle5"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            {{ `${$globalModuleConstants.dashboardModule.deliveryAccuracy}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2">
            <StackedBarChart
              v-if="chart4"
              :labels="labels2"
              :datasets="chartDatasets2"
              :options="stackBarOptions2"
              :height="375"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            {{ `${$globalModuleConstants.dashboardModule.onTimeDelivery}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2">
            <StackedBarChart
              v-if="chart5"
              :labels="labels3"
              :datasets="chartDatasets3"
              :options="stackBarThinOptions"
              :height="364"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-card class="rounded-lg" elevation="0" style="height: 100%">
          <v-card-title class="d-flex align-center chart-titles">
            {{ `${$globalModuleConstants.dashboardModule.onTimeInstallation}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2">
            <StackedBarChart
              v-if="chart6"
              :labels="labels4"
              :datasets="chartDatasets4"
              :options="stackBarThinOptions2"
              :height="364"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StackedBarChart from "@/components/BaseComponents/Charts/StackedBarChart.vue";
import BarChart from "@/components/BaseComponents/Charts/BarChart.vue";
import DoughnutChart from "@/components/BaseComponents/Charts/DoughnutChart.vue";
import GaugeChart from "@/components/BaseComponents/Charts/GaugeChart.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import SubmitButton from "@/components/GeneralButtons/SubmitButton.vue";
import CustomTitle from "@/components/General/Title.vue";
import moment from "moment";

export default {
  name: "dashboardIndex",
  components: {
    StackedBarChart,
    BarChart,
    DoughnutChart,
    GaugeChart,
    SelectField,
    DateField,
    SubmitButton,
    CustomTitle,
  },
  data() {
    return {
      todaysTaskChart: [],
      pendingTaskChart: [],
      deliveryAccuracyChart: [],
      deliveryStatusChart: [],
      installationStatusChart: [],
      orderStatusDetailData: [],

      tripChartData: {},
      orderStatusChartData: {},
      orderStatusDetailsData: {},
      total_order_count: 100,
      total_order_percentage: 0,
      today: new Date().toISOString().slice(0, 10),
      isValid: false,

      deliveryKPIData: [],

      labels1: [],
      chartDatasets1: [],

      labels2: [],
      chartDatasets2: [],

      labels3: [],
      chartDatasets3: [],

      labels4: [],
      chartDatasets4: [],

      teamFilter: {},
      allDrivers: [],
      formErrors: [],

      gaugeValue1: 0,
      gaugeValue2: 0,
      gaugeValue3: 0,
      gaugeValue4: 0,
      gaugeValue5: 0,

      gaugeTitle1: "Overall KPI",
      gaugeTitle2: "Pre-Delivery Call",
      gaugeTitle3: "On-Time Delivery",
      gaugeTitle4: "Courtesy Of Team",
      gaugeTitle5: "Professionalism Of Team",

      barChartData: {
        labels: [],
        datasets: [],
      },
      gaugeChart1: {
        datasets: [
          {
            data: [75],
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
        ],
      },
      guageChart2: {
        datasets: [
          {
            data: [75],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
        ],
      },
      guageChart3: {
        datasets: [
          {
            data: [75],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
        ],
      },
      guageChart4: {
        datasets: [
          {
            data: [75],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
        ],
      },
      guageChart5: {
        datasets: [
          {
            data: [75],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
        ],
      },

      lateDeliveredInfoData: [],
      chart1: false,
      chart2: false,
      chart3: false,
      chart4: false,
      chart5: false,
      chart6: false,
      chart7: false,
      stackBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Teams",
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "No. of Tasks",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          title: {
            display: true,
            text: "Stacked Bar Chart Title",
            fontSize: 18,
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontSize: 16,
          },
        },
      },
      stackBarOptions2: {
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Teams",
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "No. of Delivery",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          title: {
            display: true,
            text: "Stacked Bar Chart Title",
            fontSize: 18,
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontSize: 16,
          },
        },
      },
      stackBarThinOptions: {
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          bar: {
            categoryPercentage: 0.2,
            barPercentage: 1,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: "No. of Delivery",
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Teams",
              },
            },
          ],
        },
        plugins: {
          title: {
            display: true,
            text: "Stacked Bar Chart Title", // Set your desired chart title here
            fontSize: 18,
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontSize: 16,
          },
        },
      },
      stackBarThinOptions2: {
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          bar: {
            categoryPercentage: 0.2,
            barPercentage: 1,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: "No. of Installation",
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Teams",
              },
            },
          ],
        },
        plugins: {
          title: {
            display: true,
            text: "Stacked Bar Chart Title", // Set your desired chart title here
            fontSize: 18,
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontSize: 16,
          },
        },
      },
      barOptions: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "No. of Trips",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 0.2,
              categoryPercentage: 0.8,
              scaleLabel: {
                display: true,
                labelString: "Teams",
              },
            },
          ],
        },
      },
      donoutChartData: {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
        labels: [],
      },
      donutOptions: {
        responsive: true,
        legend: false,
        cutoutPercentage: 60,
      },
      labelDetails: [
        { label: "Red", details: "Details for Red" },
        { label: "Yellow", details: "Details for Yellow" },
        { label: "Blue", details: "Details for Blue" },
      ],
    };
  },
  methods: {
    getAllDrivers() {
      this.$api.driver
        .getDriverList({ limit: "all" })
        .then((res) => {
          this.allDrivers = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTodaysTaskChart(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getdashboardTodaysTask(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.todaysTaskChart = res.data.data;
        })
        .catch((err) => {
          console.error(err);
          this.todaysTaskChart = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    getPendingTaskChart(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getdashboardPendingTasks(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          const responseData = res.data.data;

          // Extract datasets from the response
          const datasets = responseData.datasets;

          // Map datasets to the desired format
          const chartDatasets = datasets.map((dataset) => {
            return {
              label: dataset.label,
              backgroundColor: dataset.backgroundColor,
              data: Object.values(dataset.data),
            };
          });

          // Set labels from the response
          this.labels1 = responseData.labels;

          // Set the chart datasets directly
          this.chartDatasets1 = chartDatasets;

          this.chart1 = true;
        })
        .catch((err) => {
          console.error(err);
          this.pendingTaskChart = [];
          this.$bus.$emit("showLoader", false);
        });
    },

    getTripChart(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getTripsDetails(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.barChartData.labels = res.data.data.labels; // Set labels from the response
          this.barChartData = res.data.data;
          this.chart2 = true;
        })
        .catch((err) => {
          console.error(err);
          this.barChartData = {
            labels: [],
            datasets: [], // Reset to empty datasets
          };
          this.$bus.$emit("showLoader", false);
        });
    },
    getDeliveryAccuracy(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getDeliveryAccuracy(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          const responseData = res.data.data;

          const datasets = responseData.datasets;

          const chartDatasets = datasets.map((dataset) => {
            return {
              label: dataset.label,
              backgroundColor: dataset.backgroundColor,
              data: Object.values(dataset.data),
            };
          });

          // Set labels from the response
          this.labels2 = responseData.labels;

          // Set the chart datasets directly
          this.chartDatasets2 = chartDatasets;

          this.chart4 = true;
        })
        .catch((err) => {
          console.error(err);
          this.deliveryAccuracyChart = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    getDeliveryStatus(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getDeliveryDetails(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          const responseData = res.data.data;

          const datasets = responseData.datasets;

          const chartDatasets = datasets.map((dataset) => {
            return {
              label: dataset.label,
              backgroundColor: dataset.backgroundColor,
              data: Object.values(dataset.data),
            };
          });

          // Set labels from the response
          this.labels3 = responseData.labels;

          // Set the chart datasets directly
          this.chartDatasets3 = chartDatasets;

          this.chart5 = true;
        })
        .catch((err) => {
          console.error(err);
          this.deliveryStatusChart = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    getInstallationStatus(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getInstallationDetails(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          const responseData = res.data.data;

          const datasets = responseData.datasets;

          const chartDatasets = datasets.map((dataset) => {
            return {
              label: dataset.label,
              backgroundColor: dataset.backgroundColor,
              data: Object.values(dataset.data),
            };
          });

          // Set labels from the response
          this.labels4 = responseData.labels;

          // Set the chart datasets directly
          this.chartDatasets4 = chartDatasets;

          this.chart6 = true;
        })
        .catch((err) => {
          console.error(err);
          this.deliveryAccuracyChart = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    getOrderStatusChart(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.chart3 = false;
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getOrderStatus(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.orderStatusChartData = res.data.data;

          this.donoutChartData.datasets[0].data =
            this.orderStatusChartData[0].datasets[0].data;
          this.donoutChartData.datasets[0].backgroundColor =
            this.orderStatusChartData[0].datasets[0].backgroundColor;
          this.donoutChartData.labels = this.orderStatusChartData[0].labels;

          this.total_order_count = this.donoutChartData.datasets[0].data.reduce(
            (total, currentValue) => total + currentValue,
            0
          );
          if (this.total_order_count > 0) {
            this.total_order_percentage = 100;
          }
          else{
            this.chart3 = false;
          }

          this.chart3 = true;
        })
        .catch((err) => {
          console.error(err);
          this.donoutChartData = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    getOrderStatusDetails(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getOrderStatusDetails(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.orderStatusDetailData = res.data.data;
          this.chart3 = true;
        })
        .catch((err) => {
          console.error(err);
          this.orderStatusDetailData = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    getDeliveryKPI(params = {}) {
      params = {
        limit: "all",
        driver_id: this.teamFilter.driver ? this.teamFilter.driver : 0,
        ...params,
      };

      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getDeliveryKPI(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.deliveryKPIData = res.data.data;
          this.deliveryKPIData.forEach((item) => {
            console.log("item",item)
            switch (item.category) {
              case `${this.$globalModuleConstants.dashboardModule.overallRating}`:
                this.gaugeValue1 = item.average_rating;
                break;
                case `${this.$globalModuleConstants.dashboardModule.preDeliveryCall}`:
                this.gaugeValue2 = item.average_rating;
                break;
                case `${this.$globalModuleConstants.dashboardModule.courtesyofTeam}`:
                this.gaugeValue4 = item.average_rating;
                break;
                case `${this.$globalModuleConstants.dashboardModule.onTimeDelivery}`:
                this.gaugeValue3 = item.average_rating;
                break;
                case `${this.$globalModuleConstants.dashboardModule.professionalismOfTeam}`:
                this.gaugeValue5 = item.average_rating;
                break;
              default:
                break;
            }
          });
          this.chart7 = true;
        })
        .catch((err) => {
          console.error(err);
          this.deliveryKPIData = [];
          this.$bus.$emit("showLoader", false);
        });
    },
    resetGaugeValuesAndFetchData() {
      this.gaugeValue1 = 0;
      this.gaugeValue2 = 0;
      this.gaugeValue3 = 0;
      this.gaugeValue4 = 0;
      this.gaugeValue5 = 0;
      if (this.teamFilter.from_date && this.teamFilter.to_date) {
        const queryParams = {
          from_date: this.teamFilter.from_date,
          to_date: this.teamFilter.to_date,
        };
        this.getDeliveryKPI(queryParams);
      } else {
        this.getDeliveryKPI();
      }
    },
    autoSetDate() {
      if (!this.teamFilter.from_date) {
        this.teamFilter.from_date = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");
      }

      if (!this.teamFilter.to_date) {
        this.teamFilter.to_date = moment().format("YYYY-MM-DD");
      }
      const queryParams = {
        from_date: this.teamFilter.from_date,
        to_date: this.teamFilter.to_date,
      };
      this.applyTimeRangeFilterToFunctions(queryParams);
    },
    applyTimeRangeFilter() {
      if (this.teamFilter.from_date && this.teamFilter.to_date) {
        const queryParams = {
          from_date: this.teamFilter.from_date,
          to_date: this.teamFilter.to_date,
        };
        this.applyTimeRangeFilterToFunctions(queryParams);

        this.$emit("timeRangeFilterApplied", queryParams);
      } else {
        this.isValid = false;

        this.formErrors.expiry_date = "Both dates are required";
      }
    },
    applyTimeRangeFilterToFunctions(queryParams) {
      this.chart1 = false;
      this.chart2 = false;
      this.chart3 = false;
      this.chart4 = false;
      this.chart5 = false;
      this.chart6 = false;
      this.total_order_count = 0;
      this.total_order_percentage = 0;
      this.getTodaysTaskChart(queryParams);
      this.getPendingTaskChart(queryParams);
      this.getTripChart(queryParams);
      this.getDeliveryAccuracy(queryParams);
      this.getDeliveryStatus(queryParams);
      this.getInstallationStatus(queryParams);
      this.getOrderStatusChart(queryParams);
      this.getOrderStatusDetails(queryParams);
      this.getDeliveryKPI(queryParams);
    },
  },
  mounted() {
    // this.getTodaysTaskChart();
    // this.getPendingTaskChart();
    // this.getTripChart();
    // this.getDeliveryAccuracy();
    // this.getDeliveryStatus();
    // this.getInstallationStatus();
    // this.getOrderStatusChart();
    // this.getOrderStatusDetails();
    this.getDeliveryKPI();
    this.getAllDrivers();
    this.autoSetDate();
  },
  computed: {
    canvasElement() {
      return this.$refs.gaugeChartCanvasRef;
    },
    validateFromDate() {
      return (value) => {
        if (this.teamFilter.to_date && value > this.teamFilter.to_date) {
          return "From Date cannot be greater than To Date";
        }
        return true;
      };
    },
    validateToDate() {
      return (value) => {
        if (this.teamFilter.from_date && value < this.teamFilter.from_date) {
          return "To Date cannot be less than From Date";
        }
        return true;
      };
    },
  },
};
</script>

<style scoped>
.stackedChart1 {
  background-color: white;
  height: 50%;
}
.stackedChart2 {
  background-color: white;
  height: 60%;
}
.barChart1 {
  background-color: white;
  height: 50%;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sub-text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.v-card.total-task-card {
  border-radius: 8px;
  border: 1px solid var(--Ptimary, #009ef7);
  background: rgba(0, 158, 247, 0.1) !important;
}
.v-card.pending-card {
  border-radius: 8px;
  border: 1px solid var(--job-status-pending, #5e6278);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #5e6278 !important ;
}
.v-card.in-progress-card {
  border-radius: 8px;
  border: 1px solid var(--job-status-accepted, #ffa621);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #ffa621 !important;
}
.v-card.completed-card {
  border-radius: 8px;
  border: 1px solid var(--Green, #50cd89);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #50cd89 !important;
}
.custom-text-h5 {
  color: var(--b-1, #3f4254);
  text-align: right;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.custom-text-h6 {
  color: var(--b-1, #3f4254);
  text-align: right;
  /* Details View Title */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.legend-container {
  padding: 10px;
}
.white-background {
  background-color: white;
}

.info-label {
  margin-left: 10px;
  font-weight: bold; /* You can adjust font weight as per your design */
}

.custom-divider {
  max-width: 70% !important; /* Adjust the width as per your preference */
}
.chart-titles {
  color: var(--Title, #181c32);
  /* FT1 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>