import { vehicle } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getVehicleList(params = {}) {
    return get(`${vehicle.base}`, {
      params: params,
    });
  },
  getVehicleSelectList(params = {}) {
    return get(`${vehicle.base}select/`, {
      params: params,
    });
  },
  getVehicleObject(id) {
    return get(`${vehicle.base}${id}/`);
  },
  addVehicle(data) {
    return post(`${vehicle.base}`, data);
  },
  updateVehicle(data, id) {
    return put(`${vehicle.base}${id}/`, data);
  },
});
