import Blank from "@/layouts/blank.vue";

const supportRoutes = [
  {
    path: `support/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `support-list`,
        component: () => import("@/pages/support/SupportList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `support-add`,
        component: () => import("@/pages/support/SupportAdd.vue"),
      },
      {
        path: "details/:id",
        name: `support-details`,
        component: () => import("@/pages/support/SupportDetails.vue"),
      },
    ],
  },
];
export default supportRoutes;
