
import router from "@/router";
import axios from "axios";

function authHeader() {
  let user = localStorage.getItem("user");

  if (user) {
    return `token ${user}`;
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let head = authHeader();
  if (head) {
    config.headers.Authorization = head;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (
      err.response.status == 404 ||
      err.response.status == 403 ||
      err.response.status == 401
    ) {
      router.push({
        name: "error-page",
        params: { status: err.response.status },
      });
    }
    throw err;
  }
);

export default axiosInstance;
