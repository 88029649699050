import { driverDocument } from "@/utils/urlConstants";

export default (axios) => ({
  getDriverDocumentList(params) {
    return axios.get(`${driverDocument.base}`, { params: params });
  },
  addDriverDocument(data) {
    return axios.post(`${driverDocument.base}`, data);
  },
  updateDriverDocument(data, id) {
    return axios.put(`${`${driverDocument.base}`}${id}/`, data);
  },
  deleteDriverDocument(id) {
    return axios.delete(`${`${driverDocument.base}`}${id}/`);
  },
});
