<template>
  <v-btn
    height="34"
    v-bind="$attrs"
    v-on="$listeners"
    color="primary"
    :disabled="disabled"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
    text: {
      default: "Submit",
    },
  },
};
</script>