import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const checklistRoutes = [
  {
    path: `${globalModuleConstants.checklist.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.checklist.value}-list`,
        component: () =>
          import("@/pages/config/checklist/ChecklistList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.checklist.value}-add`,
        component: () => import("@/pages/config/checklist/ChecklistAdd.vue"),
      },
      {
         path: ":id/edit",
        name: `${globalModuleConstants.checklist.value}-edit`,
        component: () =>
          import("@/pages/config/checklist/ChecklistEdit.vue"),
      },
    ],
  },
];

export default checklistRoutes;
