import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const exceptionRoutes = [
  {
    path: `${globalModuleConstants.exception.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.exception.value}-list`,
        component: () =>
          import("@/pages/config/exception/ExceptionList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.exception.value}-add`,
        component: () => import("@/pages/config/exception/ExceptionAdd.vue"),
      },
      {
         path: ":id/edit",
        name: `${globalModuleConstants.exception.value}-edit`,
        component: () =>
          import("@/pages/config/exception/ExceptionEdit.vue"),
      },
    ],
  },
];

export default exceptionRoutes;
