<template>
  <v-text-field v-model="locationName" :id="fieldId" hide-details="auto" outlined v-bind="$attrs" v-on="$listeners"
    :clearable="clearable" :dense="dense" :rules="[
        ...(isRequired ? [(val) => !!val || `${label} is Required`] : []),
        ...this.fieldRules,
      ]" :label="`${label}${isRequired ? ' *' : ''}`" class="Border-Remove" background-color="background" />
</template>

<script>
import { loadGoogleInstance } from '@/utils/googleMapHandlers';
// import { useUserInfoStore } from '@/stores/userInfo';
export default {
  name: "GoogleAutocomplete",
  props: {
    value: {
      required: true,
    },
    fieldId: {
      required: true,
    },
    dense: {
      default: true,
    },
    label: {
      required: true,
    },
    isRequired: {
      default: false,
      type: Boolean
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    fieldRules: {
      default: () => [],
    },
    types: {
      default: () => [],
    },
  },
  // setup() {
  //   const userInfoStore = useUserInfoStore()

  //   return { userInfoStore }
  // },
  data: () => ({
    autocomplete: null,
  }),
  computed: {
    locationName: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      const profile = JSON.parse(localStorage.getItem("userProfile"));
      return profile || {};
    },
  },
  watch: {
    locationName(val) {
      if (!val) {
        this.$emit("locationSelected", null);
      }
    },
  },
  methods: {
    loadAutocomplete() {
      const interval = setInterval(() => {
        let options = {
          fields: [
            "address_components",
            "geometry",
            "formatted_address",
            "name",
          ],
        };
        if (this.types) {
          options.types = this.types;
        }

        const input = document.getElementById(this.fieldId);

        if (input) {
          clearInterval(interval);

          this.autocomplete = new window.google.maps.places.Autocomplete(
            input,
            options
          );
          input.setAttribute("placeholder", "");

          this.autocomplete.addListener("place_changed", this.placeChanged);

          // if (this.userInfoStore.country_code) {
            this.autocomplete.setComponentRestrictions({
              // country: [this.userInfoStore?.country_code] || ['AE'],
              country: ['AE'],

            });
          // }
        }
      }, 200);
    },
    placeChanged() {
      try {
        const place = this.autocomplete.getPlace();
        const obj = {
          place: place,
        };

        const geometry = place.geometry;
        const addressComponents = place.address_components;

        obj.coordinates = {
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
        };

        if (
          addressComponents &&
          addressComponents[addressComponents.length - 2] &&
          addressComponents[addressComponents.length - 2].long_name
        ) {
          obj.state = addressComponents[addressComponents.length - 2].long_name;
        }

        this.locationName = place.formatted_address;
        obj.name = place.name;
        obj.formatted_address = place.formatted_address;

        for (let i = 0; i < addressComponents.length; i++) {
          const addressType = addressComponents[i].types[0];

          if (addressType == "country") {
            obj.country_code = addressComponents[i].short_name;
            obj.country = addressComponents[i].long_name;
          }
        }
        this.$emit("locationSelected", obj);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    loadGoogleInstance()
    setTimeout(() => {
      this.loadAutocomplete();
    }, 600)
  },
};
</script>
