import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const tripRoutes = [
  {
    path: `${globalModuleConstants.trip_module.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.trip_module.value}-list`,
        component: () => import("@/pages/trips/TripList.vue"),
        default: true,
      },
      {
        path: "details/:id",
        name: `${globalModuleConstants.trip_module.value}-details`,
        component: () => import("@/pages/trips/TripDetails.vue"),
      },
      {
        path: "create/",
        name: `${globalModuleConstants.trip_module.value}-create`,
        component: () => import("@/pages/trips/TripAdd.vue"),
      },
      // {
      //   path: ":id/edit",
      //   name: `${globalModuleConstants.trip_module.value}-edit`,
      //   component: () => import("@/pages/trip/TripEdit.vue"),
      // },
    ],
  },
];

export default tripRoutes;
