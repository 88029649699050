import "@/assets/styles/base.scss";

import Vue from "vue";
import VueRouter from "vue-router";

import Admin from "@/layouts/admin.vue";
import DashBoardPage from "@/pages/dashboard/index.vue";

import dashboardRoutes from "./dashboard";
import gettingStartedRoutes from "./gettingStarted";
import configRoutes from "./configuration";
import assetRoutes from "./asset";
import itemRoutes from "./item";
import orderRoutes from "./order";
import userRoutes from "./user";
import salesPersonRoutes from "./salesPerson";
import integrationRoutes from "./integration";
import tripRoutes from "./trips";
import operationRoutes from "./operations";
import reportRoutes from "./report";
import videoPlayerRoutes from "./videoPlayer";
import supportRoutes from "./support";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Admin,
    children: [
      {
        path: "/dashboard",
        component: DashBoardPage,
        name: `dashboard-page`,
        default: true,
      },
      ...operationRoutes,
      ...gettingStartedRoutes,
      ...configRoutes,
      ...assetRoutes,
      ...itemRoutes,
      ...orderRoutes,
      ...userRoutes,
      ...salesPersonRoutes,
      ...integrationRoutes,
      ...tripRoutes,
      ...reportRoutes,
      ...videoPlayerRoutes,
      ...supportRoutes,
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  routes: routes,
});

export default router;
