<template>
  <v-navigation-drawer
    v-model="drawer"
    expand-on-hover
    :mini-variant="true"
    :clipped="true"
    fixed
    app
    dark
    color="primary"
    v-bind="$attrs"
    width="335px"
    class="app-nav-shadow Scrollbar"
  >
    <v-list dense shaped class="pr-1 pt-2">

      <v-list-item v-for="(link, i) in nav_items" :key="i" class="pa-0">
        <v-list-item-group
          v-if="!link.subLinks"
          v-model="selectedItem"
          class="Full-Width"
          color="white"
        >
          <v-list-item :exact="!!link.exact" router :to="link.to">
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group v-else class="py-1 Full-Width" color="white">
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(subLink, i) in link.subLinks"
            router
            exact
            :key="i"
            :to="subLink.to"
          >
            <v-list-item-action>
              <v-icon>{{ subLink.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="pl-6">

              <v-list-item-title>{{ subLink.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

      </v-list-item>
      <v-list-item class="pa-0" @click="getMobileAPPlink"
        v-if="userType == 'admin' || userType == 'normal_user' || userType == 'system_administrator' "
      >
        <v-list-item-group
          class="Full-Width"
          color="white"
        >
        <v-list-item>
          <v-list-item-action >
              <v-icon>mdi mdi-cellphone-arrow-down-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>

              <v-list-item-title>Download APK</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
      
      </v-list-item>

    </v-list>
  </v-navigation-drawer>


</v-navigation-drawer>
</template>

<script>
import { getUserDetailsLocalStorage } from "@/utils/functions";
import SubmitButton from "../GeneralButtons/SubmitButton.vue";
import GridDownloadButtonVue from "../GeneralButtons/Grid/GridDownloadButton.vue";
import DownloadAPKButton from "../GeneralButtons/DownloadAPKButton.vue";
// import { getPermissions } from "@/utils/functions";
export default {
  components: {
    GridDownloadButtonVue,
    SubmitButton,
    DownloadAPKButton,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      selectedItem: 0,
      baseNav: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: "/dashboard",
          exact: true,
        },
        {
          icon: "mdi-chart-bar",
          title: "Operations",
          to: `/operations/`,
          exact: true,
        },
        // {
        //   icon: "mdi-rocket-launch-outline",
        //   title: "Getting Started",
        //   to: {
        //     name: "getting-started",
        //   },
        //   exact: true,
        // },
        {
          icon: "mdi-truck-fast",
          title: "Trips",
          to: `/trips/`,
          exact: true,
        },
        {
          icon: "mdi-application-cog-outline",
          title: "Configurations",
          subLinks: [
            {
              icon: "mdi-domain",
              title: "Branch",
              to: "/config/branch",
            },
            {
              icon: "mdi-map",
              title: "Project",
              to: "/config/project",
            },
            {
              icon: "mdi-warehouse",
              title: "Warehouse",
              to: "/config/warehouse",
            },
            {
              icon: "mdi-car-estate",
              title: "Vehicle Type",
              to: "/config/vehicle-type",
            },
            {
              icon: "mdi-file-document-multiple-outline",
              title: "Document Type",
              to: "/config/document-type",
            },
            {
              icon: "mdi mdi-note-multiple",
              title: "Exception",
              to: "/config/exception",
            },
            {
              icon: "mdi-list-status",
              title: "Checklist",
              to: "/config/checklist",
            },
          ],
        },
        {
          icon: "mdi-bus-multiple",
          title: "Asset Management",
          subLinks: [
            {
              icon: "mdi-account",
              title: "Team",
              to: `/asset/team`,
            },
            {
              icon: "mdi-truck-fast-outline",
              title: "Vehicle",
              to: `/asset/vehicle`,
            },
          ],
        },
        {
          icon: "mdi-alpha-i-circle",
          title: "Items",
          to: "/item/",
          exact: true,
        },
        {
          icon: "mdi-earth",
          title: "Orders",
          to: `/order/`,
          exact: true,
        },
        {
          icon: "mdi mdi-account-group",
          title: "User Management",
          // permissionKey: userLabel.permissionKey,
          subLinks: [
            {
              icon: "mdi-account-multiple-outline",
              title: "Users",
              to: `/users/`,
            },
            // {
            //   icon: "mdi-account-key",
            //   title: roleLabel.plural,
            //   to: `/${userManagementLabel.pathName}/${roleLabel.pathName}/`,
            // },
          ],
        },
        {
          icon: "mdi-account-box",
          title: "Sales Representative",
          to: `/sales_persons/`,
          exact: true,
        },
        {
          icon: "mdi-database-sync",
          title: "Integration",
          to: "/integration/",
          exact: true,
        },
        {
          icon: "mdi-chart-areaspline",
          title: "Reports",
          subLinks: [
            {
              icon: "mdi-checkbox-marked-circle-auto-outline",
              title: "Task Report",
              to: `/task-report`,
              name: "task-report",
            },
            {
              icon: "mdi-comment-quote",
              title: "Customer Feedback Report",
              to: `/customer-feedback-report`,
              name: "customer-feedback-report",
            },
            {
              icon: "mdi-account-group",
              title: "Team Wise Operation Report",
              to: `/team-wise-operation-report`,
              name: "team-wise-operation-report",
            },
            {
              icon: "mdi-truck-fast-outline",
              title: "Dispatch Report",
              to: `/dispatch-report`,
              name: "dispatch-report",
            },
          ],
        },
        {
          icon: "mdi-ticket-account",
          title: "Support",
          to: "/support/",
          exact: true,
        },
      ],
    };
  },
  computed: {
    userType() {
      let user = getUserDetailsLocalStorage();
      return user.user_type;
    },
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    items() {
      if (
        this.userType != "customer" &&
        this.userType != "sales_representative"
      ) {
        return [...this.baseNav];
      } else if (this.userType == "sales_representative") {
        return [
          {
            icon: "mdi-view-dashboard",
            title: "Dashboard",
            to: "/dashboard",
            exact: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Operations",
            to: `/operations/`,
            exact: true,
          },
          {
            icon: "mdi-earth",
            title: "Orders",
            to: `/order/`,
            exact: true,
          },
        ];
      } else {
        return [
          {
            icon: "mdi-view-dashboard",
            title: "Dashboard",
            to: "/dashboard",
            exact: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Operations",
            to: `/operations/`,
            exact: true,
          },
          {
            icon: "mdi-earth",
            title: "Orders",
            to: `/order/`,
            exact: true,
          },
          {
            icon: "mdi-account-box",
            title: "Sales Representative",
            to: `/sales_persons/`,
            exact: true,
          },
          {
            icon: "mdi-database-sync",
            title: "Integration",
            to: "/integration/",
            exact: true,
          },
        ];
      }
      // return this.baseNav;
    },
    nav_items() {
      return this.filterNav(this.items);
    },
  },
  methods: {
    getMobileAPPlink(params = {}) {
      params = {
        ...params,
      };
      this.$api.mobileapk
        .mobileAPP(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          const downloadLink = document.createElement("a");
          downloadLink.href = res.data.data.apk_file;
          downloadLink.setAttribute("download", "app.apk");
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: err.response.data.error.error,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    filterNav(navList) {
      // return navList && Array.isArray(navList)
      // ? navList.filter((navItem) => {
      //   if (navItem.subLinks && Array.isArray(navItem.subLinks)) {
      //     navItem.subLinks = this.filterNav(navItem.subLinks);
      //     if (navItem.subLinks.length == 0) {
      //       return false;
      //     }
      //   }
      //   if (!navItem.permissionKey) {
      //     return true;
      //   }
      //   const permission = getPermissions(navItem.permissionKey);
      //   return permission
      //     ? Object.values(permission).indexOf(true) > -1
      //     : false;
      // })
      // : [];
      return navList;
    },
  },
  mounted() {
    // let user_type = localStorage.getItem("user_type");
    // if (!user_type) {
    //   this.$router.push("/");
    // } else {
    //   this.userType = user_type;
    // }
  },
};
</script>
<style scoped>
.mdi-10px {
  font-size: 10px;
}
.v-list-item .v-list-item__title,
.app-list-margin .v-list-item__subtitle {
  font-size: 15px !important;
}
</style>