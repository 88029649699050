import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const salesPersonRoutes = [
  {
    path: `${globalModuleConstants.salesPersonModule.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.salesPersonModule.value}-list`,
        component: () => import("@/pages/salespersons/UserList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.salesPersonModule.value}-add`,
        component: () => import("@/pages/salespersons/UserAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${globalModuleConstants.salesPersonModule.value}-edit`,
        component: () => import("@/pages/salespersons/UserEdit.vue"),
      },
    ],
  },
];
export default salesPersonRoutes;
