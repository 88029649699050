<template>
  <v-menu
    v-model="openNotifications"
    :close-on-content-click="false"
    max-width="400px"
    nudge-left="350"
    nudge-top="-20"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        top
        overlap
        :content="notificationCount"
        :color="notificationCount == 0 ? '' : 'primary'"
      >
        <!-- <Tooltip left tooltipText="Notification"> -->
        <!-- <template #activator> -->
        <v-btn
          text
          class="mr-n5"
          v-on="on"
          v-bind="attrs"
          @click="getNotificationList(true)"
          :class="openNotifications ? 'primary--text' : ''"
        >
          <v-icon color="primary">mdi-bell</v-icon>
        </v-btn>
        <!-- <v-icon
          v-on="on"
          v-bind="attrs"
          @click="getNotificationList"
          color="primary"
        >
          mdi-bell
        </v-icon> -->
        <!-- </template> -->
        <!-- </Tooltip> -->
      </v-badge>
    </template>

    <v-card width="400px" flat class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-body-1 white--text"> Notifications </span>
        <v-spacer></v-spacer>
        <v-icon
          v-if="userNotifications && userNotifications.length != 0"
          color="white"
          @click="markAllNotificationAsRead"
        >
          mdi-delete
        </v-icon>
      </v-card-title>

      <v-card-text
        style="max-height: 350px"
        class="pa-0 overflow-y-auto"
        @scroll="handleNotificationScroll"
        id="notificationCard"
      >
        <div
          v-if="!userNotifications.length"
          class="d-flex justify-center pa-3"
        >
          <span>No Notifications To Show!</span>
        </div>
        <div v-else>
          <div v-for="(notification, index) in userNotifications" :key="index">
            <v-row
              :class="[
                'ma-0',
                'my-1',
                notification.is_read ? 'grey--text' : '',
              ]"
              :style="
                !notification.is_read
                  ? { backgroundColor: '#e5f5fc', cursor: 'Pointer' }
                  : { backgroundColor: 'white', cursor: 'Pointer' }
              "
            >
              <v-col cols="12" class="pa-0 ma-0">
                <hr style="opacity: 0.4" />
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-center">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px;
                  "
                >
                  <v-icon
                    color="primary"
                    v-if="
                      notification.notification.notification_category == 'Job'
                    "
                  >
                    mdi-train-car-container
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-else-if="
                      notification.notification.notification_category == 'Bid'
                    "
                  >
                    mdi-cash-marker
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-else-if="
                      notification.notification.notification_category ==
                      'Campaign'
                    "
                  >
                    mdi-ticket-percent
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-else-if="
                      notification.notification.notification_category ==
                      'Invoice'
                    "
                  >
                    mdi-receipt-text
                  </v-icon>
                  <v-icon color="primary" v-else> mdi-receipt-text </v-icon>
                </div>
              </v-col>
              <v-col cols="10" class="d-flex align-center">
                <v-row no-gutters>
                  <v-col cols="12" class="text-body-2 font-weight-bold">
                    <span>
                      {{ notification.notification.title }}
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-caption">
                    {{ notification.notification.message }}
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-caption font-weight-bold mt-1 d-flex align-center"
                  >
                    {{ formatDate(notification.notification.created, true) }}
                  </v-col>
                  <v-col
                    v-if="!notification.is_read"
                    cols="6"
                    class="text-caption font-weight-bold mt-1 d-flex justify-end pa-0 ma-0"
                  >
                    <!-- mdiCheck -->
                    <v-btn
                      x-small
                      color="primary"
                      class="ma-2 white--text"
                      @click="
                        userNotificationMarkAsRead(notification.id, index)
                      "
                    >
                      <v-icon left dark color="white"> mdi-check </v-icon>
                      Mark as Read
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
  
<script>
import Tooltip from "@/components/General/Tooltip.vue";
import { formatDate } from "@/utils/functions";

export default {
  components: {
    Tooltip,
  },

  props: {
    value: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      formatDate,
      userNotificationCount: 0,
      notificationCount: 0,
      userNotifications: [],
      limit: 10,
      pageNo: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    openNotifications: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    deleteAllUserNotifications() {
      // const config = {
      //   status: "red",
      //   title: `Notifications`,
      //   text: `Are you sure want to clear all notifications?`,
      // };

      // this.$confirm(config)
      //   .then(() => {
      let notificationIdList = [];

      this.userNotifications.forEach((notification) =>
        notificationIdList.push(notification.id)
      );
      this.userNotificationMarkAsRead(notificationIdList);
      // })
      // .catch((err) => {});
    },
    async getNotificationList(params) {
      params = {
        limit: this.limit,
        offset: this.offset,
        is_read: false,
        ...params,
      };
      // if (this.userNotifications.length) {
      //   params.offset = this.userNotifications.length;
      // }
      this.$api.notification
        .getNotificationList(params)
        .then((res) => {
          this.userNotifications = res.data.data;
          // this.userNotificationCount = res.data.count;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleNotificationScroll() {
      // let notificationCard = document.getElementById("notificationCard");
      // const clientHeight = notificationCard.clientHeight;
      // const scrollHeight = notificationCard.scrollHeight;
      // const scrollTop = notificationCard.scrollTop;
      // let callNotifications =
      //   Math.ceil(clientHeight + scrollTop) == scrollHeight;
      // if (callNotifications) {
      //   this.getNotificationList();
      // }
    },
    removeMarkAsReadNotification(id) {
      if (this.userNotifications.find((v) => v.id == id)) {
        this.userNotifications.splice(
          this.userNotifications.find((v) => v.id == id),
          1
        );
        this.getNotificationCount();
      }
    },
    markAllNotificationAsRead() {
      let data;
      data = {
        is_read: true,
      };
      this.$api.notification
        .notificationMarkAllAsRead(data)
        .then((res) => {
          this.getNotificationCount();
          if (this.userNotifications && this.userNotifications.length == 0) {
            this.openNotifications = true;
          }
          this.getNotificationList();
        })
        .catch((err) => {
          console.log(err);
          // this.$bus.$emit("showLoader", false);
        });
    },
    userNotificationMarkAsRead(idList, index) {
      // this.$bus.$emit("showLoader", true);
      let data = {
        is_read: true,
      };
      let payload = {
        id: idList,
        data: data,
      };

      this.$api.notification
        .notificationMarkAsRead(payload)
        .then((res) => {
          this.removeMarkAsReadNotification(idList);
          if (this.userNotifications && this.userNotifications.length == 0) {
            this.openNotifications = true;
          }
          // this.getNotificationList();
        })
        .catch((err) => {
          console.log(err);
          // this.$bus.$emit("showLoader", false);
        });
    },
    getNotificationCount() {
      this.$api.notification
        .getNotificationCount()
        .then((res) => {
          this.notificationCount = res.data.data.unread_count;
        })
        .catch((err) => {
          console.log(err);
          // this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.getNotificationCount();
    setInterval(() => {
      this.getNotificationCount();
    }, 30000);
  },
  // beforeDestroy() {
  //   this.$bus.$off("socketConnected");
  //   this.$bus.$off("userNotificationReceived");
  // },
};
</script>
  
<style scoped>
.card-top-outlined {
  border-top: 1px solid #0000004f !important;
}

.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Chat-Height-Class {
  height: calc(100vh - 200px) !important;
}
</style>