import { vehicleType } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getVehicleTypeList(params = {}) {
    return get(`${vehicleType.base}`, {
      params: params,
    });
  },
  getVehicleTypeSelectList(params = {}) {
    return get(`${vehicleType.base}select/`, {
      params: params,
    })
  },
  getVehicleTypeObject(id) {
    return get(`${vehicleType.base}${id}/`);
  },
  addVehicleType(data) {
    return post(`${vehicleType.base}`, data);
  },
  updateVehicleType(data, id) {
    return put(`${vehicleType.base}${id}/`, data);
  },
});
