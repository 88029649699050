import "@/assets/styles/base.scss";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify.js";

import globalObject from "@/utils/globalConfig.js";
import globalModuleObject from "@/utils/globalModuleConstants.js";
import globals from "@/plugins/globals";

import api from "@/plugins/api.js";
import axiosInstance from "@/plugins/axios.js";

import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import AutoComplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import GoogleAutocomplete from "@/components/FormBaseComponents/GoogleAutocomplete.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import router from "./router";

// export const bus = new Vue();
Vue.prototype.$bus = new Vue();
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$api = api;

Vue.component("InputField", InputField);
Vue.component("SelectField", SelectField);
Vue.component("AutoComplete", AutoComplete);
Vue.component("MultiSelect", MultiSelect);
Vue.component("GoogleAutocomplete", GoogleAutocomplete);
Vue.component("DateField", DateField);

Vue.use(vuetify);

Vue.config.productionTip = false;

Vue.prototype.$globalConstant = globalObject;
Vue.prototype.$globals = globals;
Vue.prototype.$globalModuleConstants = globalModuleObject;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
