import { warehouse } from "@/utils/urlConstants";

export default ({ get, post, put }) => ({
  getWarehouseList(params = {}) {
    return get(`${warehouse.base}`, {
      params: params,
    });
  },
  getWarehouseSelectList(params = {}) {
    return get(`${warehouse.base}select/`, {
      params: params,
    })
  },
  getWarehouseObject(id) {
    return get(`${warehouse.base}${id}/`);
  },
  addWarehouse(data) {
    return post(`${warehouse.base}`, data);
  },
  updateWarehouse(data, id) {
    return put(`${warehouse.base}${id}/`, data);
  },
});
