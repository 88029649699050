import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import globalModuleConstants from "@/utils/globalModuleConstants";

// import globalObject from "@/utils/globalConfig.js";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      dark: {},
      light: {
        ...globalModuleConstants.defaultTheme,
        primary: "#2c4c9c",
        secondary: "#3F4254",
        // accent: "#FFFFFF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        heading: "#90A4AE",
        "sub-heading": "#90A4AE",
        "text-color": "#91939F",
        background: "#E8F1FB",
        greyLighten2: "#edecec",
        greyLighten1: "#d2d2d2",
      },
    },
  },
});
