const globalModuleConstants = {
  configurationModule: Object.freeze({
    configuration: "Configuration",
    branch: "Branch",
    project: "Project",
    warehouse: "Warehouse",
    projectPlural: "Projects",
    branchPlural: "Branches",
    warehousePlural: "Warehouses",
    vehicleType: "Vehicle Type",
    vehicleTypePlural: "Vehicle Types",
    documentType: "Document Type",
    documentTypePlural: "Document Types",
    zone: "Zone",
    planningTemplate: "Planning Template",
    exceptionPlural: "Exceptions",
    exception: "Exception",
    pathName: "config",
    value: "config",
    checklist: "Checklist",
  }),

  branchModule: Object.freeze({
    pathName: "branch",
    value: "branch",
    branchName: "Branch Name",
    branchNameValidation: "Branch Name is required",
    branchCode: "Branch Code",
    branchCodeValidation: "Branch Code is required",
    branchCountry: "Select Country",
    branchCountryValidation: "Country is required",
    branchCurrency: "Currency",
    branchPhonePrefix: "Phone Prefix",
    branchTimeZone: "Timezone",
    branchAddress: "Branch Address",
    branchAddressValidation: "Branch Address is required",
    branchLatitude: "Latitude",
    branchLongitude: "Longitude",
  }),

  projectModule: Object.freeze({
    pathName: "project",
    value: "project",
    projectName: "Project Name",
    branchName: "Branch",
    projectNameValidation: "Project Name is required",
    projectID: "Project ID",
    projectIDValidation: "Project ID is required",
    projectBranch: "Select Branch",
    projectBranchValidation: "Branch is required",
    projectAddress: "Project Address",
    projectAddressValidation: "Project Address is required",
    projectLatitude: "Latitude",
    projectLatitudeValidation: "Latitude is required",
    projectLongitude: "Longitude",
    projectLongitudeValidation: "Longitude is required",
    projectAutocompleteInspection: "Autocreate Inspection",
    projectAutocompleteDelivery: "Autocreate Delivery",
    projectAutocompleteInstallation: "Autocreate Installation",
    projectIsDefault: "Is Default",
  }),

  driverDocumentLabel: Object.freeze({
    name: "Driver Document",
    plural: "Driver Documents",
    // Used in services
    apiName: "driverDocument",
    apiModelName: "DriverDocument",
    keyword: "driver_document",
    permissionKey: "asset_management",
    modelName: "driver",
  }),

  exception: Object.freeze({
    pathName: "exception",
    value: "exception",
    edit: "Edit",
    view: "View",
    delete: "Delete",
    exceptionName: "Exception Name",
    exceptionDescription: "Exception Description",
    exceptionType: "Exception Type",
  }),
  checklist: Object.freeze({
    pathName: "checklist",
    value: "checklist",
    edit: "Edit",
    view: "View",
    delete: "Delete",
    checklistTitle: "Checklist Title",
    checklistDescription: "Checklist Description",
    checklistType: "Checklist Type",
  }),

  warehouseModule: Object.freeze({
    pathName: "warehouse",
    value: "warehouse",
    warehouseID: "Warehouse ID",
    warehouseIDValidation: "Warehouse ID is required",
    warehouseName: "Warehouse Name",
    warehouseNameValidation: "Warehouse Name is required",
    warehouseProject: "Select Project",
    warehouseBranch: "Select Branch",
    warehouseProjectValidation: "Project is required",
    warehouseBranchValidation: "Branch is required",
    warehouseAddress: "Address",
    warehouseAddressValidation: "Address is required",
    warehouseLatitude: "Latitude",
    warehouseLatitudeValidation: "Latitude is required",
    warehouseLongitude: "Longitude",
    warehouseLongitudeValidation: "Longitude is required",
  }),

  video_player: Object.freeze({
    pathName: "video-player",
    value: "video-player",
  }),

  vehicleTypeModule: Object.freeze({
    pathName: "vehicle-type",
    value: "vehicle-type",
    vehicleCategory: "Vehicle Category",
    vehicleType: "Vehicle Type",
    vehicleTypeName: "Vehicle Type Name",
    vehicleTypeNameValidation: "Vehicle Type Name is required",
    vehicleTemperatureControlled: "Temperature Controlled",
    vehicleTypeValidation: "Vehicle Type is required",
    vehicleTypePayloadCapacity: "Payload Capacity",
    vehicleTypeTonnageCapacity: "Tonnage Capacity",
    vehicleTypeTonnageCapacityValidation:
      "Tonnage Capacity cannot be empty or zero",
    vehicleTypePayloadCapacityValidation:
      "Payload Capacity cannot be empty or zero",
    vehicleTypeCBMCapacity: "CBM Capacity",
    vehicleTypeCBMCapacityValidation: "CBM capacity cannot be empty or zero.",
    vehicleTypeLength: "Length",
    vehicleTypeLengthValidation: "Length is required",
    vehicleTypeWidth: "Width",
    vehicleTypeBreadthValidation: "Width is required",
    vehicleTypeHeight: "Height",
    vehicleTypeHeightValidation: "Height is required",
    vehicleTypeCostPerKm: "Cost Per Km",
    vehicleTypeCostPerKmValidation: "Cost Per Km is required",
  }),

  documentTypeModule: Object.freeze({
    pathName: "document-type",
    value: "document-type",
    documentCategory: "Document Category",
    documentCategoryValidation: "Document Category is required",
    documentIsRequired: "Is Required?",
    documentHasExpiryDate: "Has Expiry Date?",
    documentName: "Document Name",
    documentNameValidation: "Document Name is required",
    documentProject: "Project",
    documentTypeProject: "Select Project",
    documentTypeProjectValidation: "Project is required",
  }),

  fleetManagementModule: Object.freeze({
    vehicle: "Vehicle",
    vehiclePlural: "Vehicles",
    driver: "Team",
    driverPlural: "Teams",
    pathName: "asset",
    value: "asset",
  }),

  vehicleModule: Object.freeze({
    pathName: "vehicle",
    value: "vehicle",
    vehicleNumber: "Vehicle Number",
    vehicleNumberValidation: "Vehicle Number is required",
    vehicleType: "Select Vehicle Type",
    vehicleTypeValidation: "Vehicle Type is required",
    vehicleProject: "Project",
    vehicleProjectValidation: "Project is required",
    vehicleFuelType: "Fuel Type",
    vehicleFuelTypeValidation: "Fuel Type is required",
    vehicleVehicleType: "Vehicle Type",
    vehicleVehicleTypeValidation: "Vehicle Type is required",
    vehicleMake: "Vehicle Make",
    vehicleMakeValidation: "Vehicle Make is required",
    vehicleInsurancePolicyNumber: "Insurance Policy Number",
    vehicleInsurancePolicyExpirationDate: "Insurance Expiry Date",
    vehicleRegistrationExpirationDate: "Registration Expiry Date",
    vehicleNotes: "Notes",
    vehicleModel: "Vehicle Model",
    vehicleModelValidation: "Vehicle Model is required",
    vehicleManufacturingYear: "Manufacturing Year",
    vehiclePurchaseValue: "Purchase Value",
    vehicleManufacturingYearValidation: "Manufacturing Year is required",
    vehicleCostPerKm: "Cost Per Km",
    vehicleCostPerKmValidation: "Vehicle Cost is required",
    vehicleOdometerReading: "Odometer Reading",
    vehicleOdometerReadingValidation: "Odometer Reading is required",
    vehicleOwnerShip: "Select Vehicle Ownership",
    vehicleOwnerShipValidation: "Vehicle Ownership is required",
    vehicleStatus: "Status",
  }),

  driverModule: Object.freeze({
    pathName: "team",
    value: "team",
    driverName: "Team Name",
    driverFirstName: "First Name",
    driverLastName: "Last Name",
    driverUsername: "Username",
    driverPassword: "Password",
    driverConfirmPassword: "Confirm Password",
    driverContactNumber: "Contact Number",
    driverAddress: "Address",
    driverLatitude: "Latitude",
    driverLongitude: "Longitude",
    driverProject: "Project",
    driverVehicle: "Vehicle",
    driverShiftStart: "Shift Start",
    driverShiftEnd: "Shift End Time",
    driverStatus: "Status",
    driverOnBoardDate: "On Board Date",
  }),

  integrationModule: Object.freeze({
    integration: "Integration",
    pathName: "integration",
    value: "integration",
    referenceNumber: "Order Reference Number",
    integrationFile: "Integration File",
    integrationErrors: "Integration Errors",
    integrationStatus: "Integration Status",
    fetch: "Fetch Integration Files",
    upload: "Upload Integration Files",
    upload_manual: "Manual Upload",
    created_at: "Integration Time",
    success: "Success",
    fileType: "File Type",
  }),

  reportModule: Object.freeze({
    report: "Report",
    pathName: "report",
    value: "report",
    success: "Success",
    taskReport: "Task Report",
    taskReportValue: "task-report",
    customerFeedback: "Customer Feedback",
    customerFeedbackValue: "customer-feedback-report",
    teamWiseReport: "Team Wise Operation",
    teamWiseReportValue: "team-wise-operation-report",
    dispatchReport: "Dispatch Report",
    dispatchReportValue: "dispatch-report",
    downloadAllTaskReports: "Download Task Report",
    downloadAllCustomerFeedbacks: "Download Customer Feedback Report",
    downloadAllOperationReport: "Download Operation Report",
    downloadDispatchReport: "Download Dispatch Report",
  }),

  item_master: Object.freeze({
    item: "Item",
    itemPlural: "Items",
    pathName: "item",
    value: "item",
    itemNumber: "Item Number",
    itemDescription: "Item Description",
    itemIsSerializable: "Serialized",
    itemStatus: "Status",
    itemWeight: "Weight",
    itemLength: "Length",
    itemWidth: "Width",
    itemHeight: "Height",
    itemVolume: "Volume",
    itemCaseFactor: "Case Factor",
    itemInstallationTime: "Installation Time (in mins)",
    itemDownloadAll: "Download All Items",
    itemInspectionRequired: "Inspection",
    itemInstallationRequired: "Installation",
    itemOffloadingTime: "Off Loading Time (in mins)",
  }),

  task_module: Object.freeze({
    SiteInspection: "site_inspection",
    Delivery: "delivery",
    Installation: "installation",
    Pickup: "pickup",
  }),

  order_module: Object.freeze({
    pathName: "order",
    value: "order",
    order: "Order",
    orderPlural: "Orders",
    orderSelectOptionLabel: {
      statusList: [
        {
          label: "Assigned",
          value: "assigned",
        },
        {
          label: "Cancelled",
          value: "cancelled",
        },
        {
          label: "Open Transaction",
          value: "open_transaction",
        },
        {
          label: "Unassigned",
          value: "unassigned",
        },
      ],
      statusType: {
        assigned: "assigned",
        unassigned: "unassigned",
        cancelled: "cancelled",
        openTransaction: "open_transaction",
      },
      warningTypeList: [
        {
          value: "no_coordinates",
          label: "No Coordinates",
        },
        {
          value: "outside_serviceable_area",
          label: "Outside Serviceable Area",
        },
        // {
        //   value: "failed_during_bulk_upload",
        //   label: "Failed During Bulk Upload",
        // },
        // {
        //   value: "split_recommended",
        //   label: "Split Recommended",
        // },
      ],
      orderTypeList: [
        {
          label: "Delivery",
          value: "delivery",
        },
        {
          label: "Pickup",
          value: "pickup",
        },
      ],
    },
  }),

  userModule: Object.freeze({
    pathName: "users",
    value: "users",
    user: "User",
    userPlural: "Users",
    userFirstName: "First Name",
    userLastName: "Last Name",
    userUsername: "Username",
    userEmail: "Email",
    userCountryCode: "Country Code",
    userContactNumber: "Contact Number",
    userType: "User Type",
  }),

  salesPersonModule: Object.freeze({
    pathName: "sales_persons",
    value: "sales_persons",
    user: "Sales Representative",
    userPlural: "Sales Representatives",
    userFirstName: "First Name",
    userLastName: "Last Name",
    userUsername: "Username",
    userEmail: "Email",
    userCountryCode: "Country Code",
    userContactNumber: "Contact Number",
    userType: "User Type",
  }),

  trip_module: Object.freeze({
    pathName: "trips",
    value: "trips",
    trips: "Trip",
    tripsPlural: "Trips",
    tripID: "Trip ID",
    tripDate: "Trip Date",
    tripStatus: "Status",
    tripDriver: "Team",
    tripVehicle: "Vehicle",
    tripPlannedStart: "Planned Start",
    tripPlannedEnd: "Planned End",
    tripActualStart: "Actual Start",
    tripActualEnd: "Actual End",
    tripReferenceNo: "Trip Reference No",
    tripTotalTasks: "Total Tasks",
  }),

  support: Object.freeze({
    support: "Support Ticket",
  }),

  order: Object.freeze({
    order: "Order",
    orderPlural: "Orders",
    b2bOrder: "B2B Order",
    b2bOrderPlural: "B2B Orders",
    b2cOrder: "B2C Order",
    b2cOrderPlural: "B2C Orders",
  }),

  gridActionsTooltip: Object.freeze({
    edit: "Edit",
    view: "View",
    delete: "Delete",
    download: "Download",
  }),

  operationModule: Object.freeze({
    pathName: "operations",
    value: "operations",
    operation: "Operation",
    operationPlural: "Operations",
  }),

  dashboardModule: Object.freeze({
    tasks: "Tasks",
    total_task: "Total Task",
    pending: "Pending",
    in_progress: "In Progress",
    completed: "Completed",
    pendingTasks: "Pending Tasks",
    tripChart: "Trip Chart",
    orderChart: "Order Chart",
    total: "Total",
    deliveryKPI: "Delivery KPI",
    deliveryAccuracy: "Delivery Accuracy",
    onTimeDelivery: "On-Time Delivery",
    onTimeInstallation: "On Time Installation",
    overallRating: "Overall Rating",
    preDeliveryCall: "Pre-Delivery Call",
    courtesyofTeam: "Courtesy of Team",
    professionalismOfTeam: "Professionalism Of Team",
  }),

  defaultTheme: Object.freeze({
    task_type_site_inspection: "#FF9800",
    task_type_delivery: "#2196F3",
    task_type_installation: "#4CAF50",
    task_type_pickup: "#5B6E80",

    light_task_type_site_inspection: "#FFE0B2",
    light_task_type_delivery: "#BBDEFB",
    light_task_type_installation: "#C8E6C9",
    light_task_type_pickup: "#CFD8DC",

    task_successful: "#4CAF50",
    task_failed: "#F44336",
    task_unassigned: "#FF9800",
    task_unattempted: "#5B6E80",
    task_in_progress: "#2196F3",

    order_open: "#5B6E80",
    order_unassigned: "#FF9800",
    order_assigned: "#9C27B0",
    order_in_progress: "#2196F3",
    order_completed: "#4CAF50",
    order_cancelled: "#800000",

    order_item_in_progress: "#2196F3",
    order_item_pending: "#FF9800",
    order_item_completed: "#4CAF50",
    order_item_cancelled: "#262626",
    order_item_failed: "#F44336",

    trip_scheduled: "#5B6E80",
    trip_active: "#2196F3",
    trip_completed: "#4CAF50",
    trip_cancelled: "#262626",

    installation_not_required: "#607D8B",
    installation_pending: "#9E9E9E",
    installation_completed: "#4CAF50",

    team_gold: "#B8860B",
    team_diamond: "#005A8D",
    team_bronze: "#8B4513",
    team_silver: "#696969",

    ticket_status_open: "#FF9800",
    ticket_status_closed: "#5B6E80",
    ticket_status_acknowledged: "#9C27B0",
    ticket_status_in_progress: "#2196F3",
    ticket_status_resolved: "#4CAF50",
    ticket_status_cancelled: "#262626",
    ticket_status_reopened: "#FFD600",

    light_ticket_status_open: "#FFE0B2",
    light_ticket_status_closed: "#CDD3D9",
    light_ticket_status_acknowledged: "#E1BEE7",
    light_ticket_status_in_progress: "#BCDFFB",
    light_ticket_status_resolved: "#C9E7CA",
    light_ticket_status_cancelled: "#BDBDBD",
    light_ticket_status_reopened: "#FFF3B2",

    ticket_priority_high: "#F44336",
    ticket_priority_low: "#4CAF50",
    ticket_priority_medium: "#FF9800",
  }),

  teamDetailModule: Object.freeze({
    completedTaskToolTipMessage: "This is Information Pop-up text. Lorem ipsum",
    delayedTripToolTipMessage: "This is Information Pop-up text. Lorem ipsum ",
    delayedDeliveryTaskToolTipMessage:
      "This is Information Pop-up text. Lorem ipsum ",
    delayedInstallationTaskToolTipMessage:
      "This is Information Pop-up text. Lorem ipsum ",
  }),
};
Object.freeze(globalModuleConstants);

export default globalModuleConstants;
