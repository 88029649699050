<template>
    <GridIconButton
      v-bind="$attrs"
      v-on="$listeners"
      color="background"
      iconColor="primary"
      icon="mdi mdi-download-box"
    />
  </template>
  
  <script>
  import GridIconButton from "@/components/GeneralButtons/Grid/GridIconButton.vue";
  export default {
    components: {
      GridIconButton,
    },
  };
  </script>
  
  <style></style>
  