<template>
  <v-dialog v-model="openUserProfileDialog" width="55%">
    <v-card>
      <v-card-title class="primary">
        <v-row>
          <v-col cols="6">
            <h5 class="white--text">User Profile</h5>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="openUserProfileDialog = false" color="white"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="6">
            <InputField
              v-model="userData.first_name"
              label="First Name"
              name="user-form-first-name"
            />
          </v-col>
          <v-col cols="6">
            <InputField
              v-model="userData.last_name"
              label="Last Name"
              name="user-form-last-name"
            />
          </v-col>
          <v-col cols="6">
            <InputField
              v-model="userData.username"
              label="Username"
              name="user-form-user-name"
            />
          </v-col>
          <v-col cols="6">
            <InputField
              v-model="userData.email"
              label="Email"
              name="user-form-email"
            />
          </v-col>
          <v-col cols="6">
            <InputField
              v-model="userData.contact_number"
              label="Contact Number"
              name="user-form-contact-number"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <SubmitButton @click="submit" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SubmitButton from "../GeneralButtons/SubmitButton.vue";
export default {
  components: {
    SubmitButton,
  },
  props: {
    value: Boolean,
    userId: Number,
  },
  data() {
    return {
      userData: {},
    };
  },
  watch: {
    openUserProfileDialog(val) {
      if (val && this.userId) {
        this.getUserObject();
      }
    },
  },
  computed: {
    openUserProfileDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getUserObject() {
      this.$bus.$emit("showLoader", true);
      this.$api.user
        .getUserObject(this.userId)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.userData = { ...res.data.data };
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Something went wrong!",
            color: "error",
          });
        });
    },
    submit() {},
  },
};
</script>

<style>
</style>