import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const userRoutes = [
  {
    path: `${globalModuleConstants.userModule.pathName}/`,
    component: Blank,
    default: true,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.userModule.value}-list`,
        component: () => import("@/pages/users/UserList.vue"),
        default: true,
      },
      {
        path: "add/",
        name: `${globalModuleConstants.userModule.value}-add`,
        component: () => import("@/pages/users/UserAdd.vue"),
      },
      {
        path: "edit/:id",
        name: `${globalModuleConstants.userModule.value}-edit`,
        component: () => import("@/pages/users/UserEdit.vue"),
      },
    ],
  },
];
export default userRoutes;
