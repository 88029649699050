import { support, version } from "@/utils/urlConstants";

export default (axios) => ({
  getSupportList(params = {}) {
    return axios.get(`${support.base}`, {
      params: params,
    });
  },
  getSupportSelectList(params = {}) {
    return axios.get(`${support.base}select/`, {
      params: params,
    });
  },
  getSupportView(id) {
    return axios.get(`${support.base}${id}/view/`);
  },
  addSupport(data) {
    return axios.post(`${support.base}`, data);
  },
  updateSupportStatus(payload) {
    return axios.put(
      `${support.base}${payload.id}/update_status/`,
      payload.data
    );
  },
  uploadSupportDocument(payload) {
    return axios.post(
      `${support.base}${payload.id}/upload_document/`,
      payload.data
    );
  },
  deleteDocument(id) {
    return axios.delete(`${version}support_document/${id}/`);
  },
  addCommentAttachment(data) {
    return axios.post(`${version}support_comment/`, data);
  },
  getCommentList(id) {
    return axios.get(`${support.base}${id}/comment/`);
  },
});
