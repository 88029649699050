import Blank from "@/layouts/blank.vue";

const gettingStartedRoutes = [
  {
    path: "getting-started/",
    component: Blank,
    children: [
      {
        path: "",
        name: "getting-started",
        component: () => import("@/pages/getting-started/GettingStartedForm.vue"),
        default: true,
      },
    ],
  },
];

export default gettingStartedRoutes;
