import Blank from "@/layouts/blank.vue";
import globalModuleConstants from "@/utils/globalModuleConstants";

const orderRoutes = [
  {
    path: `${globalModuleConstants.order_module.pathName}/`,
    component: Blank,
    children: [
      {
        path: "",
        name: `${globalModuleConstants.order_module.value}-list`,
        component: () => import("@/pages/order/OrderList.vue"),
        default: true,
      },
      {
        path: "details/:id",
        name: `${globalModuleConstants.order_module.value}-details`,
        component: () => import("@/pages/order/OrderDetails.vue"),
      },
      {
        path: "add/",
        name: `${globalModuleConstants.order_module.value}-add`,
        component: () => import("@/pages/order/OrderAdd.vue"),
      },
      {
        path: ":id/edit",
        name: `${globalModuleConstants.order_module.value}-edit`,
        component: () => import("@/pages/order/OrderEdit.vue"),
      },
      {
        path: "videoPlayer",
        name: `${globalModuleConstants.order_module.value}-video-player`,
        component: () => import("@/pages/order/VideoPlayer.vue"),
        props: true,
      },
    ],
  },
];

export default orderRoutes;
